<template>
  <div class="appscreen booking-form">
   <div class="booking-vue">
   <header>
   <div v-if="!bookingDay"><h1><!-- span>Réservation</span --></h1></div>
   <div v-else>
    <h1 v-if="isAdmin||isManager">{{local(bookingDay)}} <small v-if="!!bookingHour"><br />{{this.time}} <a href="#" @click.prevent="modalMoving = !modalMoving" v-if="bookingConfirm"><small><span v-if="!modalMoving">[Déplacer]</span><span v-else>[Annuler le déplacement]</span></small></a></small></h1>
   </div>
   </header>
    <div class="form-container">
     <form v-on:submit.prevent="moveBooking" v-if="modalMoving" class="moving">

      <p class="field">
       <label>Date</label>
       <input required v-model="today" type="date" onkeydown="return false" :min="minMove" :max="maxMove"/>
      </p>

      <div class="daypick field">
      <label>Heure</label>

        <div :class="!!bookingHour&&!isSelecting?'hourSelect selected':'hourSelect'" >
          <Day :day="today" @book="book" :key="today" :coach="bookingCoach"/>
          <div v-if="!!bookingHour&&!isSelecting" class="selectedHour" @click.prevent="isSelecting=!isSelecting">{{bookingHour}} <span>x</span></div>
        </div>

      </div>

      <div class="submit">
       <button class="btn" type="submit">
         <span>Déplacer</span>
       </button>
      </div>

     </form>
     <form v-on:submit.prevent="addBooking" v-else>

       <p class="field">
         <label v-if="isAdmin|isManager">Date</label>
         <input required v-model="today" type="date" :min="min" :max="max" @change="bindDayToday" onkeydown="return false" v-if="isAdmin || isManager" />
         <DatePicker v-model="tomorrow" key="todayUpdate"  @change="bindDayTomorrow" :disabled="!isSelecting" @unbook="unbook" v-else />
       </p>

       <input type="hidden" name="upd" :value="todayUpdate">
       <div class="daypick field" v-if="(!!today || !!tomorrow) && !this.time && mapBookings.length">
        <label :class="isManager||isAdmin?'':'mobile-only'">Heures</label>
        <div v-if="isAdmin||isManager">
          <div :class="!!bookingHour&&!isSelecting?'hourSelect selected':'hourSelect'" v-for="bcoach,uid in Coachs" v-bind:key="uid">
            <div class="coachName" v-if="isSelecting||!isSelecting&&uid==bookingCoach">{{bcoach.displayName.split(" ")[0]}}</div>
            <Day :day="today" @book="coachBook" :key="todayUpdate" v-if="!time && isSelecting" :coach="uid"/>
            <div v-if="!!bookingHour&&!isSelecting&&uid==bookingCoach" class="selectedHour" @click.prevent="unbook">{{bookingHour}} <span>x</span></div>
          </div>
        </div>
        <div v-else>
          <div :class="!!bookingHour&&!isSelecting?'hourSelect selected':'hourSelect'" v-for="bcoach, uid in Coachs" v-bind:key="uid">
            <div class="coachName" v-if="isSelecting||!isSelecting&&uid==bookingCoach">{{bcoach.displayName.split(" ")[0]}}</div>
            <Day :day="tomorrow" @book="coachBook" :key="tomorrow" :memberBooking="true" v-if="!time && isSelecting && mapBookings" :coach="uid" :isTomorrow="tomorrow == tomorrowCopy"/>
            <div v-if="!!bookingHour&&!isSelecting&&uid==bookingCoach" class="selectedHour" @click.prevent="unbook">{{bookingHour}} <span>x</span></div>
          </div>
        </div>
       </div>
       <div v-else>
        <label :class="isManager||isAdmin?'':'mobile-only'"></label>
        <div class="hourSelect"></div>
       </div>

       <div class="desktop-container">
       <p class="field" v-if="!uid  && (isAdmin||isManager) || !uid && booking.title">
         <label>Titre</label>
          <input v-model="booking.title" type="text" v-if="isAdmin||isManager" />
       </p>

      <p class="field" v-if="isAdmin||isManager">
        <label>Couleur</label>
        <span :class="!isSwatching?'color swatching':'color'">
         <input type="radio" id="zero" v-model="booking.color" value="white">
         <label for="zero" data-color="white" @click="isSwatching=!isSwatching"><span></span></label>
         <input type="radio" id="two" v-model="booking.color" value="red">
         <label for="two" data-color="red" @click="isSwatching=!isSwatching"><span></span></label>
         <input type="radio" id="five" v-model="booking.color" value="green">
         <label for="five" data-color="green" @click="isSwatching=!isSwatching"><span></span></label>
         <input type="radio" id="six" v-model="booking.color" value="yellow">
         <label for="six" data-color="yellow" @click="isSwatching=!isSwatching"><span></span></label>
         <input type="radio" id="three" v-model="booking.color" value="purple">
         <label for="three" data-color="purple" @click="isSwatching=!isSwatching"><span></span></label>
         <input type="radio" id="four" v-model="booking.color" value="dark">
         <label for="four" data-color="dark" @click="isSwatching=!isSwatching"><span></span></label>
           <input type="radio" id="one" v-model="booking.color" value="blue">
           <label for="one" data-color="blue" @click="isSwatching=!isSwatching"><span></span></label>
         </span>
       </p>
       <input type="hidden" v-model="booking.color"  v-else>

       <div v-if="booking.color!='white'">

        <p class="field" v-if="isAdmin||isManager">
         <label>Durée</label>
         <select v-model="booking.tlength" class="select">
          <option value="30" selected>30 minutes</option>
          <option value="60">1 heure</option>
          <option value="90">1 heure 30 minutes</option>
          <option value="120">2 heures</option>
          <option value="150">2 heures 30 minutes</option>
          <option value="180">3 heures</option>
          <option value="210">3 heures 30 minutes</option>
          <option value="240">4 heures</option>
          <option value="270">4 heures 30 minutes</option>
          <option value="300">5 heures</option>
          <option value="330">5 heures 30 minutes</option>
          <option value="360">6 heures</option>
          <option value="390">6 heures 30 minutes</option>
          <option value="420">7 heures</option>
          <option value="450">7 heures 30 minutes</option>
          <option value="480">8 heures</option>
          <option value="510">8 heures 30 minutes</option>
          <option value="540">9 heures</option>
          <option value="570">9 heures 30 minutes</option>
          <option value="600">10 heures</option>
          <option value="630">10 heures 30 minutes</option>
          <option value="660">11 heures</option>
          <option value="690">11 heures 30 minutes</option>
          <option value="720">12 heures</option>
          <option value="750">12 heures 30 minutes</option>
          <option value="780">13 heures</option>
          <option value="810">13 heures 30 minutes</option>
          <option value="840">14 heures</option>
         </select>
        </p>

       <p class="field" v-if="isAdmin||isManager">
        <label>Étage</label>
        <span class="buttons radio">
         <button :class="bookingFloor==2?'btn gold':'btn'" :disabled="bookingFloor==2" @click.prevent="bookingFloor=2">En haut</button>
         <button :class="bookingFloor==3?'btn gold':'btn'" :disabled="bookingFloor==3" @click.prevent="bookingFloor=3">Au milieu</button>
         <button :class="bookingFloor==1?'btn gold':'btn'" :disabled="bookingFloor==1" @click.prevent="bookingFloor=1">En bas</button>
         <button :class="bookingFloor==0?'btn gold':'btn'" :disabled="bookingFloor==0" @click.prevent="bookingFloor=0">∅</button>
        </span>
       </p>

       <div v-if="isAdmin||isManager">
        <p class="field autocomplete" v-if="!uid && !user">
         <label>Membre</label>
          <Autocomplete :options="Members" placeholder="" @selected="autocomplete" />
         </p>
         <p class="field" v-else>
          <label>Membre</label>
          <span>
            <router-link :to="'/user/'+uid" v-if="uid">
              {{getUserName(uid)}}
            </router-link>
            <router-link :to="'/user/'+user" v-else>
              <span v-if="typeof user.displayName != 'undefined'">{{user.displayName}}</span>
              <span v-else>{{getUserName(user)}}</span>
            </router-link></span>
         </p>
       </div>
       <input type="hidden" v-model="booking.user" v-else>



      <div v-if="(isAdmin||isManager) && !!bookingGroup">
        <p class="field autocomplete" v-if="!user2">
          <label>Membre</label>
           <span v-if="this.booking.user2">
             <input type="hidden" v-model="bookingIterator2" class="small"><input type="hidden" v-model="bookingUser2Mark" class="small">
           </span>
          <Autocomplete :options="Members" placeholder="" @selected="autocomplete2" />
        </p>
        <p class="field" v-else>
         <label>Membre</label>
         <span>
         <input type="hidden" v-model="bookingIterator2" class="small"><input type="hidden" :value="bookingUser2Mark" class="small">
           <router-link :to="'/user/'+bookingUser2">
             {{getUserName(bookingUser2)}}
           </router-link>
         </span>
        </p>
      </div>
      <input type="hidden" v-model="bookingUser2" v-else>


      <div v-if="(isAdmin||isManager) && booking.userComment!=null">
        <p class="field">
          <label>Qui accompagnera ?</label>
           <span>{{booking.userComment}}</span>
        </p>
      </div>

       <p class="field" v-if="(isAdmin ||isManager)">
        <label>Nombre de personnes</label>
        <span class="buttons radio">
          <button :class="!bookingGroup?'btn gold':'btn'" :disabled="!bookingGroup" @click.prevent="bookingGroup=false">1</button>
          <button :class="bookingGroup?'btn gold':'btn'" :disabled="!!bookingGroup" @click.prevent="bookingGroup=true">2</button>
        </span>
       </p>


       <div v-if="!isAdmin && !isManager && !!bookingGroup">
         <p class="field" v-if="!bookingUser2">
           <label>Qui accompagnera ?</label>
            <input v-model="booking.userComment" type="text"/>
         </p>
       </div>

       <p class="field" v-if="isAdmin">
         <label>Comm. pour client</label>
          <input v-model="booking.managerComment" type="text"/>
       </p>

       </div>

       <div class="submit">

       <div class="buttons">
         <button @click.prevent="deleteBooking" class="btn red" v-if="isAdmin || isManager">
             <span>Supprimer</span>
         </button>
         <button type="submit" v-if="!isManager && !isAdmin && booking.confirmed!==true" class="btn" :disabled="isSelecting">
            Demander
         </button>
         <button type="submit" class="btn" v-else>
          <span v-if="booking.confirmed === false">Confirmer</span>
          <span v-else>Enregistrer</span>
         </button>
       </div>

       </div>
       <hr v-if="isAdmin" />
       <p class="field" v-if="isAdmin">
         <label>Note / Commentaire</label>
          <input v-model="booking.note" type="text"/>
       </p>

      </div>

     </form>
     </div>
   </div>
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters, mapState } from "vuex";
import Day from "../components/DayVue.vue";
import Autocomplete from "../components/AutoComplete.vue";
import DatePicker from "../components/DatePicker.vue";

export default {
  name: "HomeVue",
  props: ['day', 'time', 'user', 'coach', 'currentCoach', 'confirmed', 'title', 'note', 'color', 'floor', 'group', 'user2', 'userComment', 'managerComment', 'tlength', 'userMark', 'user2Mark'],
  components: { Day, Autocomplete, DatePicker },
  data() {
    return {
      booking: {
        date: "",
        time: "",
        booking: "",
        credits: "",
        tlength: 60,
        note: null,
        title: null,
        floor: null,
        group: null,
        color: null,
        user: null,
        user2: null,
        userComment: null,
        managerComment: null,
        coach: null
      },
      uid: null,
      modalOpened: false,
      modalMoving: false,
      today: new Date().toISOString().slice(0, 10),
      todayUpdate: 0,
      tomorrow: new Date( (new Date()).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
      tomorrowCopy: new Date( (new Date()).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
      min: new Date().toISOString().slice(0, 10),
      max:  new Date( (new Date()).getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
      minMove:
        !this.isAdmin?0:
        new Date(new Date(Date.parse(this.day)).setDate(new Date(Date.parse(this.day)).getDate()
        - (new Date(Date.parse(this.day)).getDay()-1))).toISOString().slice(0,10),
      maxMove:
        !this.isAdmin?0:
        new Date(new Date(Date.parse(this.day)).setDate(new Date(Date.parse(this.day)).getDate()
        + ((7-new Date(Date.parse(this.day)).getDay())))).toISOString().slice(0,10),
      bookingHour: null,
      bookingDay: null,
      bookingUser: null,
      bookingUser2: null,
      bookingUserMark: null,
      bookingUser2Mark: null,
      bookingUserComment: null,
      bookingManagerComment: null,
      bookingLength: 60,
      bookingColor: null,
      bookingFloor: 0,
      bookingGroup: false,
      bookingCoach: null,
      bookingCredits: null,
      bookingTitle: null,
      bookingNote: null,
      bookingConfirm: false,
      bookingIterator: null,
      bookingIterator2: null,
      isSwatching: false,
      isBooking: false,
      isSelecting: true,
      dayCoach: "UZgiD1IEymhtToWu9a3wqZTxv672",
      tick: 0
    };
  },
  computed: {
    ...mapGetters(['isUserAuth', 'isAdmin', 'isManager', 'getCurrentUser', 'getServerTime']),
    ...mapState({mapBookings:'bookings'}),
    Bookings() {
      let bookings = [];
      if(this.isAdmin || this.isManager) {
      bookings =  this.$store.state.bookings;
      } else
      bookings =  this.$store.state.allBookings;
      return bookings;
    },
    Members() {
      let members = [];
      if(this.$store.state.usersIndex != null)
      Object.keys(this.$store.state.usersIndex).sort().forEach(key => {
        this.$store.state.usersIndex[key].id = key
        members.push(this.$store.state.usersIndex[key])
      });
      return members;
    },
    Users(){
      let test = {};

      if(this.$store.state.usersIndex!=null && Object.keys(this.$store.state.usersIndex).length)
        return this.$store.state.usersIndex;
      else
        return test;
    },
    Coachs() {
      if(this.$store.state.config!=null) {
        const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
          (obj, key) => {
            if(key!='fOirURqqdgNj6mKYfWClWS5Z4Pz2')
            obj[key] = this.$store.state.config.team[key];
            return obj;
          },
          {}
        );

        return ordered;
      } return {};
    },
  },
  methods: {
    ...mapActions([
      'changeLog'
    ]),
    getUserName(uid) {
      let name ="";
      if(this.$store.state.usersIndex!=null)
      Object.keys(this.$store.state.usersIndex).forEach(key => {
        if(key == uid) name = this.$store.state.usersIndex[key].displayName;
      });
      if(name=="") return null;
      return name;

    },
      getUserCredits(uid) {

        let credits ="n/c";
        if(this.isAdmin) {
          if(this.$store.state.usersIndex!=null)
          Object.keys(this.$store.state.usersIndex).forEach(key => {
            if(key == uid) credits = this.$store.state.usersIndex[key].credits;
          });
        } else {
          credits = parseInt(this.getCurrentUser.credits) + 1;
        }
        if(credits=="n/c") return null;
        return credits;

      },
    getCoachName(uid) {
      let name ="";
      if(this.$store.state.config!=null)
      Object.keys(this.$store.state.config.team).forEach(key => {
        if(key == uid) name = this.$store.state.config.team[key].displayName;
      });
      return name;
    },
    autocomplete(data) {
      if(typeof data != "undefined" && Object.keys(data).length) {
        this.bookingUser = data.id;
        this.booking.user = data.id;
        if(typeof this.Users[data.id]!="undefined" && this.Users[data.id] != null) {
          if(typeof this.Users[data.id].iterator!="undefined" && this.Users[data.id].iterator!=null)
            this.bookingIterator = parseInt(this.Users[data.id].iterator) + 1;
          else
            this.bookingIterator = 1;
          if(typeof this.Users[data.id].creditsLog!="undefined" && this.Users[data.id].creditsLog!=null)
            for(let i in this.Users[data.id].creditsLog)
             this.bookingUserMark = this.Users[data.id].creditsLog[i];
        }
      }
    },
    autocomplete2(data) {

        if(typeof data != "undefined" && Object.keys(data).length) {
          this.bookingUser2 = data.id;
          this.booking.user2 = data.id;
          if(typeof this.Users[data.id]!="undefined" && this.Users[data.id] != null) {
            if(typeof this.Users[data.id].iterator!="undefined" && this.Users[data.id].iterator!=null)
              this.bookingIterator2 = parseInt(this.Users[data.id].iterator) + 1;
            else
              this.bookingIterator2 = 1;
            if(typeof this.Users[data.id].creditsLog!="undefined" && this.Users[data.id].creditsLog!=null)
              for(let i in this.Users[data.id].creditsLog)
               this.bookingUser2Mark = this.Users[data.id].creditsLog[i];
          }
        }
    },
    local(day) {
      const d = new Date(day);
      const event = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 3, 0, 0));
      const options = { weekday: 'long', month: 'long', day: 'numeric' };

      return event.toLocaleDateString('fr-FR', options).charAt(0).toUpperCase() + event.toLocaleDateString('fr-FR', options).slice(1)
    },
    addBooking() {

      if(this.bookingUser!=null) this.booking.user = this.bookingUser
      if(this.bookingUser2!=null) this.booking.user2 = this.bookingUser2
      if(this.bookingUserComment!=null) this.booking.userComment = this.bookingUserComment
      if(this.bookingManagerComment!=null) this.booking.managerComment = this.bookingManagerComment
      if(this.bookingFloor!=null) this.booking.floor = this.bookingFloor
      if(this.bookingGroup!=null) this.booking.group = this.bookingGroup
      if(this.bookingHour!=null) this.booking.time = this.bookingHour
      if(this.bookingDay!=null) this.booking.date = this.bookingDay
      if(this.bookingCoach!=null) this.booking.coach = this.bookingCoach

      if(!this.booking.date) this.booking.date = this.today
      if(!this.booking.confirmed) this.booking.confirmed = this.bookingConfirm
      if((this.isAdmin||this.isManager) && !this.booking.confirmed) this.booking.confirmed = true;

      this.booking.from =  this.getCurrentUser.role;


      if(this.uid!=null) this.booking.user = this.uid
      if(typeof this.currentCoach != "undefined") this.booking.coach = this.currentCoach;

      let credits = this.getUserCredits(this.booking.user);
      this.booking.credits  = credits;

      console.log('addbooking', this.booking);
      console.log(this.$store.dispatch("updateBooking", this.booking));

      if(!this.isAdmin && !this.isManager)  {
        let notifications = {};
        notifications.user = this.booking.coach;
        const dateN = new Date(this.booking.date);
        const dateNF = [
          ('0' + dateN.getDate()).slice(-2),
          ('0' + (dateN.getMonth() + 1)).slice(-2)
        ].join('/');
        notifications.text = 'Nouvelle demande de réservation ' + dateNF;
        notifications.uid = this.getCurrentUser.uid;
        this.$store.dispatch("addNotifications", notifications);
      }

      if(typeof this.day == "undefined") { setTimeout(()=>{this.$router.push('/')},1000); }

      if(!this.isAdmin && !this.isManager)  {
        let users = {};
        users.user = this.booking.user;
        users.user2 = this.booking.user2;
        users.date = this.booking.date;
        this.$store.dispatch("markAsCalled", users);
      }

      this.$emit('booking', {booking: this.booking});

      let rtype = "modification";
      if(typeof this.confirmed != "undefined") {
        if(this.confirmed == null) rtype ="ajout"
      }
      if(!this.isAdmin) rtype ="demande";

      let logUser = this.booking.user; /* ok */
      let logUser2 = this.booking.user2;
      let logGroup = this.booking.group;
      let logAdmin = this.getCurrentUser.uid;
      let logCoach = this.booking.coach;
      let logData  = {
        msg : rtype + " de réservation " + this.booking.date + " " + this.booking.time,
        user : logUser,
        admin : logAdmin,
        group : logGroup,
        user2 : logUser2,
        coach : logCoach,
        }
      this.$store.dispatch("log", logData);

    },
    deleteBooking() {
      if( confirm("Supprimer cet élément ?")) {
        const booking = {};
        console.log(this.booking);
        booking.user = this.booking.user;
        booking.user2 = this.booking.user2;
        booking.time = this.booking.time.slice(0, 5);
        booking.date = this.booking.date;
        booking.group = this.booking.group;
        booking.coach = this.booking.coach;
        if(typeof this.currentCoach != "undefined") booking.coach = this.currentCoach;

        this.$store.dispatch("deleteBooking", booking);
        if(typeof this.day == "undefined") {
          setTimeout(()=>{this.$router.push('/')},1000);
        }
        this.$emit('booking', {booking: this.booking});

        let logUser = this.booking.user;
        let logUser2 = this.booking.user2;
        let logGroup = this.booking.group;
        let logAdmin = this.getCurrentUser.uid;
        let logCoach = this.booking.coach;
        let logData  = {
          msg : "annulation de réservation " + this.booking.date + " " + this.booking.time,
          user : logUser,
          admin : logAdmin,
          user2 : logUser2,
          group : logGroup,
          coach : logCoach,
          }
        this.$store.dispatch("log", logData);

      }
    },
    moveBooking() {

      const booking = {};
      booking.user = this.booking.user;
      booking.time = this.booking.time.slice(0, 5);
      booking.date = this.booking.date;
      booking.coach = this.booking.coach;
      if(this.booking.tlength>60) {
        alert("Il n'est pas possible de déplacer un booking de plus d'une heure.");
        return false;
      }
      this.$store.dispatch("deleteOldBooking", booking);
      setTimeout(()=>{

        this.booking.date = this.today;
        this.booking.time = this.bookingHour;


        if(this.bookingUser!=null) this.booking.user = this.bookingUser
        if(this.bookingFloor!=null) this.booking.floor = this.bookingFloor
        if(this.bookingGroup!=null) this.booking.group = this.bookingGroup
        if(this.bookingHour!=null) this.booking.time = this.bookingHour
        if(this.today!=null) this.booking.date = this.today

        if(!this.booking.date) this.booking.date = this.today
        if(!this.booking.confirmed) this.booking.confirmed = this.bookingConfirm
        if((this.isAdmin||this.isManager) && !this.booking.confirmed) this.booking.confirmed = true

        if(this.uid!=null) this.booking.user = this.uid

        if(typeof this.currentCoach != "undefined") this.booking.coach = this.currentCoach;

        //Supprimer ancien
        const oldbooking = {};
        oldbooking.time = this.bookingHour.slice(0, 5);
        oldbooking.date = this.today;

        for (let day in this.Bookings) {
          if(this.Bookings[day].id == oldbooking.date) {
              if(typeof this.Bookings[day][oldbooking.time]!="undefined") {
                  if(typeof this.Bookings[day][oldbooking.time][this.currentCoach]!="undefined") {
                      if(typeof this.Bookings[day][oldbooking.time][this.currentCoach].user!="undefined") oldbooking.user = this.Bookings[day][oldbooking.time][this.currentCoach].user;
                      if(typeof this.Bookings[day][oldbooking.time][this.currentCoach].user2!="undefined") oldbooking.user2 = this.Bookings[day][oldbooking.time][this.currentCoach].user2;
                  }
              }
          }
        }

        this.booking.moving = true;

        if(oldbooking.user==null && oldbooking.user2==null) {
          this.$store.dispatch("updateBooking", this.booking);
          setTimeout(()=> {
          this.$emit('booking', {booking: this.booking});
          },1000);
        } else {
          this.$store.dispatch("deleteBooking", oldbooking);
          setTimeout(()=> {
            this.$store.dispatch("updateBooking", this.booking);
            setTimeout(()=> {
            this.$emit('booking', {booking: this.booking});
            },1000);
          },1000);

        }

        if(typeof this.day == "undefined") { setTimeout(()=>{this.$router.push('/')},1000); }

        let logUser = this.booking.user;
        let logUser2 = this.booking.user2;
        let logGroup = this.booking.group;
        let logAdmin = this.getCurrentUser.uid;
        let logCoach = this.booking.coach;
        let logData  = {
          msg : "déplacement de réservation " + this.booking.date + " " + this.booking.time,
          user : logUser,
          admin : logAdmin,
          user2 : logUser2,
          group : logGroup,
          coach : logCoach,
          }
        this.$store.dispatch("log", logData);


      },1000);

    },
    book (data){
      //book here
      this.isSelecting = !this.isSelecting
      this.bookingHour = data.hour
      this.bookingCoach = data.coach
    },
    coachBook (data){
      //book here
      this.isSelecting = !this.isSelecting
      this.bookingHour = data.hour
      this.bookingCoach = data.coach
      this.bookingDay = data.day
    },
    unbook (){
      //book here
      this.isSelecting = !this.isSelecting
      this.bookingCoach = null
      this.bookingDay = null
      this.bookingHour = null
    },
    bindDayToday () {
      if(!this.isAdmin && !this.isManager)
      this.$store.dispatch("bindBookings", {start:this.today, end:this.today});
      setTimeout(() => {
        this.todayUpdate = this.todayUpdate+1;
      }, 600)
    },
    bindDayTomorrow () {
      let date = this.tomorrow;
      setTimeout(() => {
        this.todayUpdate = this.todayUpdate+1;
      }, 600)
      this.tomorrow = '2010-10-10';
      this.tomorrow = date;
      if(!this.isAdmin && !this.isManager)
     this.$store.dispatch("bindBookings", {start:this.tomorrow, end:this.tomorrow});
    }
  },
  created() {
    this.today = this.getServerTime.toISOString().slice(0, 10);
    this.tomorrow = new Date( (this.getServerTime).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    this.tomorrowCopy = new Date( (this.getServerTime).getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
    this.min = this.getServerTime.toISOString().slice(0, 10);
    this.max = new Date( (this.getServerTime).getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
  },
  mounted() {
    this.$store.dispatch("bindTimetable");

    if(typeof this.day == "undefined")
      if(this.isAdmin || this.isManager) {
        console.log('admin');
        this.bindDayToday();
      } else {
        console.log('user');
        this.bindDayTomorrow();
      }

      if(this.isAdmin || this.isManager) {
        this.max = new Date('3000-01-01');
        this.min = new Date('2000-01-01');
      }


    if(typeof this.day != "undefined") {
      this.bookingDay = this.day
      this.booking.date = this.day
      this.today = this.day.toString()
    }



    if(typeof this.time != "undefined") {
      this.bookingHour = this.time
      this.booking.time = this.time
    }

    if(typeof this.userMark != "undefined" && this.userMark!=null) {
      if(this.userMark.includes("/")) {
        this.bookingIterator = this.userMark.split("/")[0];
        this.bookingUserMark = this.userMark.split("/")[1];
        this.booking.userMark = this.userMark.split("/")[1];
      }
    }

    if(typeof this.user2Mark != "undefined" && this.user2Mark!=null) {
      if(this.user2Mark.includes("/")) {
        this.bookingIterator2 = this.user2Mark.split("/")[0];
        this.bookingUser2Mark = this.user2Mark.split("/")[1];
        this.booking.user2Mark = this.user2Mark.split("/")[1];
      }
    }

    if(typeof this.confirmed != "undefined") {
      this.bookingConfirm = this.confirmed
      this.booking.confirmed = this.confirmed
    }

    if(typeof this.user != "undefined") {
      this.bookingUser = this.user
      this.booking.user = this.user
      if(this.user==null)
        this.isBooking = false
      else
        this.isBooking = true
    } else this.isBooking = true


    if(typeof this.userComment != "undefined") {
      this.bookingUserComment = this.userComment
      this.booking.userComment = this.userComment
    }

    if(typeof this.managerComment != "undefined") {
      this.bookingManagerComment = this.managerComment
      this.booking.managerComment = this.managerComment
    }

    if(typeof this.tlength != "undefined") {
      this.bookingLength = this.tlength
      this.booking.tlength = this.tlength
    }


    if(typeof this.color != "undefined") {
      this.bookingColor = this.color
      this.booking.color = this.color
    }

    if(typeof this.floor != "undefined") {
      this.bookingFloor = this.floor
      this.booking.floor = this.floor
    }

    if(typeof this.group != "undefined") {
      this.bookingGroup = this.group
      this.booking.group = this.group
    }

    if(typeof this.title != "undefined") {
      this.bookingTitle = this.title
      this.booking.title = this.title
    }

    if(typeof this.note != "undefined") {
      this.bookingNote = this.note
      this.booking.note = this.note
    }

    if(this.isAdmin){
      if(this.getCurrentUser.uid != "fOirURqqdgNj6mKYfWClWS5Z4Pz2") {
        this.bookingCoach = this.getCurrentUser.uid;
        this.booking.coach = this.getCurrentUser.uid;
      } else {
        this.booking.coach = 'UZgiD1IEymhtToWu9a3wqZTxv672';
        this.bookingCoach = 'UZgiD1IEymhtToWu9a3wqZTxv672';
      }
    }

    if(!this.isAdmin && !this.isManager) {
      this.booking.color = 'blue';
      this.bookingColor = 'blue';
    }

    if(typeof this.user2 != "undefined"&& this.user2!=null) {
      this.bookingUser2 = this.user2
      this.booking.user2 = this.user2
    }

    if(typeof this.coach != "undefined"&& this.coach!=null) {
      this.bookingCoach = this.coach
      this.booking.coach = this.coach
    }

    if(typeof this.$route.params.uid != "undefined") {
      this.uid = this.$route.params.uid;
    }

    if(typeof this.$route.params.nb != "undefined") {
      if(this.$route.params.nb=="2")
      this.bookingGroup = true;
      if(this.$route.params.nb=="1")
      this.bookingGroup = false;
      console.log(this.bookingGroup)
    }

    this.$store.dispatch("bindConfig");
    this.$store.dispatch("bindTeam");

    setTimeout(() => {
        this.todayUpdate = this.todayUpdate+1;
      }, 2000)
  },
};
</script>
