/* import Vue from 'vue' */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

firebase.auth().languageCode = 'fr_FR';

/* Vue.config.productionTip = false */

/*
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
*/
/* createApp(App).mount('#app'); */
createApp(App).use(store).use(router).mount('#app')

//https://levelup.gitconnected.com/firebase-auth-management-in-vue-js-with-vuex-9c4a5d9cedc
/*
firebase.auth().onAuthStateChanged((user) => {
  if(user!==null) {
//        const _user = user.map((obj)=> {return Object.assign({}, obj)});
    console.log("onAuthStateChanged", user);

    this.$store.dispatch("updateUser", user);
    const details = ['uid','phoneNumber', 'email', 'displayName'];
    for(let i=0; i<details.length; i++) {
      if(user[details[i]]) this.$store.currentUser[details[i]] = user[details[i]];
    }
    this.$store.userUpdated.push('uid');
    this.$store.userUpdated.push('phoneNumber');
    this.$store.userUpdated.push('email');
    this.$store.userUpdated.push('displayName');
    this.$store.userUpdated.push('order');


  }  else {
    this.$store.currentUser = false;
  }

});
*/
