import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Get a Firestore instance
export const db = firebase
  .initializeApp({
    apiKey: "AIzaSyDA5Rgrsl2Ph3uSYAD4uJZldd3gP_AZ5Ac",
    authDomain: "l-endroit-4c75b.firebaseapp.com",
    projectId: "l-endroit-4c75b",
    storageBucket: "l-endroit-4c75b.appspot.com",
    messagingSenderId: "80317119571",
    appId: "1:80317119571:web:8241115d1d0f19c9731aa6",
    measurementId: "G-QJ79X1X2G7"
  })
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
//db.settings({ timestampsInSnapshots: true })
