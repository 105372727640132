<template>
  <div class="dropdown" v-if="options">

    <!-- Dropdown Input -->
    <input class="dropdown-input"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      autocomplete="off"
      :placeholder="placeholder" />
    <!-- Dropdown Menu -->
    <div class="dropdown-content"
      v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index">
          {{ option.displayName || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DropDown',
    template: 'DropDown',
    props: {
      name: {
        type: String,
        required: false,
        default: 'dropdown',
        note: 'Input name'
      },
      options: {
        type: Array,
        required: true,
        default: () => [],
        note: 'Options of dropdown. An array of options with id and name',
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Please select an option',
        note: 'Placeholder of dropdown'
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
        note: 'Disable the dropdown'
      },
      maxItem: {
        type: Number,
        required: false,
        default: 6,
        note: 'Max items showing'
      }
    },
    data() {
      return {
        selected: {},
        optionsShown: false,
        searchFilter: ''
      }
    },
    created() {
      this.$emit('selected', this.selected);
    },
    computed: {
      filteredOptions() {
        if(typeof this.searchFilter !="undefined") {
          if(this.searchFilter.length>2) {
            const filtered = [];
            const regOption = new RegExp(this.searchFilter, 'ig');
            for (const option of this.options) {
              if(typeof this.searchFilter!="undefined" && typeof option.displayName != "undefined")
              if (this.searchFilter.length < 1 || option.displayName.match(regOption)){
                if (filtered.length < this.maxItem) filtered.push(option);
              }
            }
            return filtered;
          } else return ''
        }
        else
          return '';

      }
    },
    methods: {
      selectOption(option) {
        this.selected = option;
        this.optionsShown = false;
        this.searchFilter = this.selected.displayName;
        this.$emit('selected', this.selected);
      },
      showOptions(){
        if (!this.disabled) {
          this.searchFilter = '';
          this.optionsShown = true;
        }
      },
      exit() {
        /*
        if (!this.selected.id) {
          this.selected = {};
          this.searchFilter = '';
        } else {
          this.searchFilter = this.selected.name;
        }
        */
        this.$emit('selected', this.selected);
        this.optionsShown = false;
      },
      // Selecting when pressing Enter
      keyMonitor: function(event) {
        //event.target.value.length
        if (event.key === "Enter" && this.filteredOptions[0])
          this.selectOption(this.filteredOptions[0]);
      }
    },
    watch: {
      searchFilter() {
        if (this.filteredOptions.length === 0) {
          this.selected = {};
        } else {
          this.selected = this.filteredOptions[0];
        }
        this.$emit('filter', this.searchFilter);
      }
    }
  };
</script>


<style lang="scss" scoped>
$white: #fcfcfc;
$grey: #bbbbbb;

  .dropdown {
    position: relative;
    display: block;
    .dropdown-input {
      cursor: pointer;
      border-radius: 3px;
      color: #333;
      display: block;
      &:hover {
      }
    }

    .dropdown-content {
      position: absolute;
      overflow: auto;
      background: $white;
      border: 1px solid $grey;
      border-top: 0;
      box-sizing: border-box;
      width: 100%;
      line-height: 1.6;
      text-align: left;
      z-index: 1;
      .dropdown-item {
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: .3em;
        &:hover {
          background-color: #e7ecf5;
        }
      }
    }
    .dropdown:hover .dropdowncontent {
      display: block;

    }
  }
</style>
