<template>
  <div class="user-message">
    <header>
    <div>
      </div>
    </header>


  <div class="form" v-if="isAdmin||isManager">
    <h2 v-if="this.$store.state.lookedAtUser">Envoi de message à {{this.$store.state.lookedAtUser.displayName}}</h2>

    <div  v-if="(isAdmin||isManager)&&this.$store.state.lookedAtUser">
      <div class="current-disclaimer" v-if="userMessages">
        <div v-for="(message, key) in userMessages" v-bind:key="message.id">
          <div v-if=" userMessages[key].text"><div>Il y a {{getDateTime(key)}}: "{{ userMessages[key].text}}"</div></div>
        </div>
      </div>
      <div>
        <textarea required v-model="message" type="text"></textarea>
      </div>
      <div style="margin-bottom: 2em;">
        <button class="button btn"  @click.prevent="emptyMessage">Réinitialiser les messages</button>&nbsp;
        <button class="button btn"  @click.prevent="saveMessage">Enregistrer</button>
      </div>
        <router-link :to="'/user/'+uid"  >Retour au client</router-link>
    </div>

    <!-- a @click.prevent="deleteManager(key,coach.displayName)" class="btn">Ajouter</a -->

  </div>


  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from "vuex";
//import Vue from 'vue';

export default {
  name: "UserMessage",
  components: {

  },
  data() {
    return {
      start: "",
      stop: "",
      message: "",
      booking: {
        date: "",
        time: "",
        booking: "",
        user: ""
      },
      user: {
        firstName: "",
        lastName: "",
        credit: "",
        gender: "",
        height: ""
      },
      index: 0,
      uid: null,
      weekupdate: 0,
      modalOpened: false,
      bookingHour: null,
      bookingDay: null,
      bookingCoach: null,
      bookingConfirm: null,
      bookingUser: null,
      bookingColor: null,
      bookingTitle: null,
      currentCoach: null
    };
  },
  computed: {
    ...mapGetters(['isAdmin','isManager','getCurrentUser', 'getServerTime']),
    Bookings() {
      return this.$store.state.bookings;
    },

    userMessages() {
        if(typeof this.$store.state.lookedAtUser.notifications != "undefined" && this.$store.state.lookedAtUser.notifications != null) {

          const messages = this.$store.state.lookedAtUser.notifications;
          const today = this.getServerTime;
          today.setHours(0,0,0,0);
          const ordered = Object.keys(messages).sort().reduce(
            (obj, key) => {

                obj[key] = messages[key];
              return obj;

            },
            {}
          );
          const result = {};
          for(let i in ordered) {
            if(ordered[i]!=null)
              result[i] = ordered[i];
          }



          return result;

        }

      return true;
    },
    Users() {
      return this.$store.state.users;
    },
    Members() {
      let users = [];
      Object.keys(this.$store.state.users).forEach(key => {
        let check = true;
        Object.keys(this.$store.state.config.team).forEach(tkey => {
          if((this.$store.state.config.team[tkey]==1||this.$store.state.config.team[tkey]==2) && tkey==this.$store.state.users[key].id) check = false
        });
        if(check) users.push(this.$store.state.users[key])
      });
      return users;
    },

    Coachs() {

            if(this.$store.state.config!=null) {
              const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
                (obj, key) => {
                  obj[key] = this.$store.state.config.team[key];
                  return obj;
                },
                {}
              );

              return ordered;
            } return {};
    },
  },
  methods: {
      ...mapActions([
        'addCredit',
        'removeCredit'
      ]),
      modalclick(event) {
        if(event.target.classList.contains('modal'))
          this.toggleDateModal();

      },
      deleteManager(uid,name) {
        if(confirm('Voulez-vous vraiement supprimer le manager ' + name + ' ?')) {
          this.$store.dispatch("deleteManager", {coach : uid});
        }
      },

      autocomplete(data) {
        if(typeof data != "undefined" && Object.keys(data).length) {
          this.bookingUser = data.id;
          this.booking.user = data.id;
        }
      },
      modalclose() {

        this.modalOpened = false;
        this.clearModal();
      },
      switchCoach(data) {
        this.currentCoach = data;
        this.$store.dispatch("bindTeamBookings", {coach : data});
        this.weekupdate = this.weekupdate + 1;
      },
      book (data){
        //book here
        this.bookingHour = data.hour
      },
    block(data) {

          this.bookingDay = data.day;
          this.booking.day = data.day

          this.bookingUnavailable = data.unavailable;
          this.booking.unavailable = data.unavailable



          this.bookingCoach = this.currentCoach;
          this.booking.coach = this.currentCoach;


          if(typeof data.booking !="undefined" && data.booking!==true) {
            this.bookingUser = data.booking
            this.booking.user = data.booking
          }

          this.booking.date = data.day;
          this.bookingHour = data.hour;
          this.booking.time = data.hour;

          this.$store.dispatch("updateCalendar", this.booking);
          this.modalclose();
    },
    bookuser(data) {

        this.bookingDay = data.day;
        this.booking.day = data.day

        this.bookingUnavailable = data.unavailable;
        this.booking.unavailable = data.unavailable



        this.bookingCoach = this.currentCoach;
        this.booking.coach = this.currentCoach;


        this.bookingUser = data.user
        this.booking.user = data.user

        this.booking.date = data.day;
        this.bookingHour = data.hour;
        this.booking.time = data.hour;

        this.$store.dispatch("updateCalendarUser", this.booking);
        this.modalclose();
      },
    getUserName(uid) {
      const users = this.Users;
      let userName = "";
      users.forEach(user=> {
        if(user.id == uid)
          userName = user.displayName
      });
      return userName
    },

    getDateTime(unix_timestamp) {

      const date = typeof unix_timestamp === 'object' ? unix_timestamp : new Date(parseInt(unix_timestamp));
      /* const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000 */
      const today = this.getServerTime;
      /* const yesterday = new Date(today - DAY_IN_MS);*/
      const seconds = Math.round((today - date) / 1000);
      const minutes = Math.round(seconds / 60);
      /* const isToday = today.toDateString() === date.toDateString();
      const isYesterday = yesterday.toDateString() === date.toDateString();
      const isThisYear = today.getFullYear() === date.getFullYear(); */


      if (seconds < 5) {
        return '1m';
      } else if (seconds < 60) {
        return `${ seconds }s`;
      } else if (seconds < 90) {
        return 'une minute';
      } else if (minutes < 60) {
        return `${ minutes }m`;
      } else if (minutes < 1440) {
        return parseInt(minutes/60) + `h`;
      } else if (minutes < 2880) {
        return `hier`;
      } else {
        return parseInt(minutes/60/24) + `j`;
      }
      /*
      else {

      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      // Hours part from the timestamp
      var hours = date.getHours();
      // Minutes part from the timestamp
      var mminutes = "0" + date.getMinutes();
      // Seconds part from the timestamp
      var sseconds = "0" + date.getSeconds();

      // Will display time in 10:30:23 format
      return hours + ':' + mminutes.substr(-2) + ':' + sseconds.substr(-2);


      } */

    },
    emptyMessage(){
      if(confirm("Supprimer les messages à l'utilisateur ?")) {
      this.message = '';
      let deletem = {};
      deletem.user = this.uid;
      this.$store.dispatch("deleteMessages",deletem);
      }
    },
    saveMessage(){
      let message = {};
      let container = {};
      let details = {};
      details.text = this.message;
      container[Date.now()] = details;
      message.user = this.uid;
      message.message = container;
      message.admin = this.$store.state.user.uid;
      this.$store.dispatch("saveMessage",message);
      setTimeout(()=>{
        this.message = '';
      },300);
    },
    changeWeek(inc, reset) {
      if(reset)
        this.index = 0;
      else
        this.index = this.index + inc;
      console.log(this.week.today)
    }
  },
  mounted() {
  },
  created() {


      if(typeof this.$route.params.uid != "undefined") {
        this.uid = this.$route.params.uid;
      }


    //this.$store.dispatch("bindBookings");
    this.$store.dispatch("bindConfig");
    this.$store.dispatch("bindUsers");
    this.$store.dispatch("bindTimetable");

    let coach = null;
    if(this.isAdmin)
      if(this.getCurrentUser.uid != "fOirURqqdgNj6mKYfWClWS5Z4Pz2")
        coach = this.getCurrentUser.uid;
      else
        coach = 'UZgiD1IEymhtToWu9a3wqZTxv672';

    this.$store.dispatch("bindTeamBookings", {coach : coach});
    this.currentCoach = coach;

      if(this.uid == null && typeof this.$route.params.uid != "undefined")
        this.uid = this.$route.params.uid;

        this.$store.dispatch("bindUser", {user : this.uid});
  },
};
</script>
