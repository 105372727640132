<template>
  <div class="coachswitch" v-if="isAdmin||isTopManager">
   <div class="buttons radio" v-if="!!this.$store.state.config">
    <button
      v-for="coach, keyid in Coachs"
      v-bind:key="coach.id"
      :value="coach"
      @click.prevent="switchCoach(keyid)"
      :disabled="currentCoach==keyid"
      :class="currentCoach==keyid?'btn gold '+keyid:'btn '+keyid">
        {{coach.displayName.split(" ")[0]}}
      </button>
   </div>
  </div>
</template>

<script>
import {  mapGetters } from "vuex";

  export default {
    name: 'DropDown',
    template: 'DropDown',
    props: ['currentCoach'],
    created() {
      this.$emit('selected', this.selected);
    },
    computed: {
      ...mapGetters(['isUserAuth', 'isAdmin', 'isManager', 'isTopManager',  'getCurrentUser']),
      Coachs() {

        if(this.$store.state.config!=null) {
          const ordered = Object.keys(this.$store.state.config.team).sort().reduce(
            (obj, key) => {
              obj[key] = this.$store.state.config.team[key];
              return obj;
            },
            {}
          );

          if(this.isManager)
          delete ordered['UZgiD1IEymhtToWu9a3wqZTxv672'];

          console.log('ordered', ordered);

          return ordered;
        } return {};
      },
    },
    methods: {
      switchCoach(id) {
        this.$emit('switchCoach', id);
      }
    },
    watch: {
    }
  };
</script>
