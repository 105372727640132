/* import Vue from 'vue'*/
import Vuex from 'vuex'
/*import { User } from '../types';*/
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '../db'
import firebase from 'firebase/compat/app';

const moment = require('moment');

/* Vue.use(Vuex) */

const initialState = () => {
  return {
    user: null,
    notifications: null,
    lookedAtUser: null,
    lookedAtBookings: null,
    lookedAtCredits: null,
    lookedAtCreditsHistory: null,
    error: null,
    users: [],
    team: [],
    logs: [],
    equipments: [],
    usersIndex: [],
    timetable: [],
    bookings: [],
    allBookings: [],
    bookingsDoc: null,
    recurring: [],
    userMeasurements: null,
    userTrainings: null,
    userArchive: null,
    state: {
      start: "07:00",
      stop: "21:00",
      team: []
    },
    config: null
  };
};

export default new Vuex.Store({
  state: initialState(),
  mutations:  {
    setUser(state, payload) {
      if(payload != null && typeof payload.notifications != "undefined" && payload.notifications!=null) {

        state.notifications = payload.notifications;
        delete payload.notifications;
      }
      state.user = payload;

    return true
    },
    setNotifications(state, payload) {
      state.notifications = payload;
      return true
    },
    setError(state, payload) {
      state.error = payload;
      return true;
    },
    setBooking() {
      return true;
    },
    addBookings(state, payload) {
      /* state.bookings.concat(payload);
      console.log(state.bookings, payload); */
      state.allBookings = payload;
      return true;
    },
    deleteBooking() {
      return true
    },
    ...vuexfireMutations
  },
  getters: {
    getCurrentUser(state) {
      return state.user;
    },
    isUserAuth(state) {
      return !!state.user;
    },
    getCurrentBookings(state) {
      return state.bookings;
    },
    getUserArchive(state) {
      return state.userArchive;
    },
    isAdmin(state) {
      let check = false;
      if(state.user!= null && state.user.role != null && state.user.role===0) check = true;
      return check;
    },
    isTopManager(state) {
      let check = false;
      if(state.user!= null && state.user.role != null && state.user.role===1) check = true;
      return check;
    },
    isManager(state) {
      let check = false;
      if(state.user!= null && state.user.role != null && state.user.role===2 || state.user.role===1) check = true;
      return check;
    },
    isUser(state) {
      let check = false;
      if(state.user!= null && state.user.role != null && state.user.role===3) check = true;
      return check;
    },
    getServerTime() {
        let timestamp =  firebase.firestore.Timestamp.now();
      //  return new Date(1706270064 * 1000); //test 26 janvier
        return new Date(timestamp.seconds * 1000);
    },
    getServerTimeSeconds() {
        let timestamp =  firebase.firestore.Timestamp.now();
        return timestamp.seconds;
    },
    someNotifications(state) {
      let check = false;
      if(state.notifications != null && Object.keys(state.notifications).length>0) check = true;
      return check;
    },
    getError(state) {
      return state.error;
    }
  },
  actions: {
    authAction({ commit }) {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {

          const docRef = db.collection("users").doc(user.uid);
          //const docRef = db.collection("users").doc("Xuv3q8rbFPNxFwiE9KQ1cp2l6843");
          docRef.get().then((doc) => {
              if (doc.exists) {
                  const data = doc.data()
                  if(typeof data != "undefined")
                  delete data.displayName
                  if(typeof data != "undefined")
                  delete data.phoneNumber
                  Object.assign(user, data);
                  commit("setUser", user);
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });

        } else {
          commit("setUser", null);
        }
      });
    },
    signOutAction({commit}) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("setUser", null);
        })
        .catch(error => {
          commit("setError", error.message);
        });
    },
    bindEquipments: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('equipments', db.collection('settings').doc('equipments'))
    }),
    bindTimetable: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('timetable', db.collection('settings').doc('week'))
    }),
    bindConfig: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('config', db.collection('settings').doc('config'))
    }),
    bindUser: firestoreAction(({ bindFirestoreRef },payload) => {

        if(!isNaN(payload.user)){
          return bindFirestoreRef('lookedAtUser', db.collection('accounts').doc(payload.user))
        } else {
          return bindFirestoreRef('lookedAtUser', db.collection('users').doc(payload.user))
        }

    }),
    bindUserArchive: firestoreAction(({ bindFirestoreRef },payload) => {

        if(!isNaN(payload.user)){
          return bindFirestoreRef('userArchive', db.collection('accounts').doc(payload.user))
        } else {
          return bindFirestoreRef('userArchive', db.collection('users').doc(payload.user))
        }

    }),
    bindUserBookings: firestoreAction(({ bindFirestoreRef },payload) => {

          return bindFirestoreRef('lookedAtBookings', db.collection('usersLogs').doc(payload.user))

    }),
    bindUserCredits: firestoreAction(({ bindFirestoreRef },payload) => {

          return bindFirestoreRef('lookedAtCredits', db.collection('creditsLogs').doc(payload.user))

    }),
    bindUserCreditsHistory: firestoreAction(({ bindFirestoreRef },payload) => {

          return bindFirestoreRef('lookedAtCreditsHistory', db.collection('creditsHistory').doc(payload.user))

    }),

    reindexUser: firestoreAction((context, payload) => {

      let collection = 'users';

        db.collection(collection).doc(payload).get().then((doc)=>{
          if(typeof doc != "undefined" && typeof doc.data() != "undefined") {
            const  data = doc.data();
            const user = {};
            const tmp = {};

            if(typeof data != "undefined")
            tmp.displayName = data.displayName;

            if(typeof data != "undefined")
            tmp.birthDate = data.birthDate;

            if(typeof data != "undefined")
            tmp.credits = data.credits;

            if(typeof data != "undefined"  && typeof data.iterator != "undefined" )
            tmp.creditsD = data.creditsD;

            if(typeof data != "undefined"  && typeof data.iterator != "undefined" )
            tmp.iterator = data.iterator;

            if(typeof data != "undefined" && typeof data.logCredits != "undefined")
            tmp.logCredits = data.logCredits;
/*
            if(typeof data != "undefined" && typeof data.call != "undefined")
            tmp.creditsLog = data.creditsLog;
*/
            if(typeof data != "undefined"  && typeof data.call != "undefined" )
            tmp.call = data.call;


            if(typeof data != "undefined"  && typeof data.xBody != "undefined" )
            tmp.xBody = data.xBody;
            if(typeof data != "undefined"  && typeof data.gourde != "undefined" )
            tmp.gourde = data.gourde;
            if(typeof data != "undefined"  && typeof data.note != "undefined" )
            tmp.note = data.note;
            if(typeof data != "undefined"  && typeof data.bill != "undefined" )
            tmp.bill = data.bill;
            if(typeof data != "undefined"  && typeof data.phoneNumber != "undefined" )
            tmp.phoneNumber = data.phoneNumber;

            if(typeof data != "undefined"  && typeof data.phoneNumber != "undefined" )
            tmp.role = data.role;
            if(typeof data != "undefined")
            tmp.gender = data.gender;
            tmp.user =  db.collection("users").doc(doc.id);
            tmp.uid =  doc.id;
            tmp.type = "user";
            tmp.bookings = "";
            user[doc.id] = tmp;


            db.collection('usersIndex').doc('users').set(user, {merge: true}).then(function(){
              console.log("Data saved successfully.");
            }).catch(function(error) {
              console.log("Data could not be saved." + error);
            });
          }

        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });


    }),
    reindexUsers: firestoreAction(() => {

      db.collection("accounts").where("role", ">", 2)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  const user = {};
                  const tmp = {};
                  tmp.displayName = doc.data().displayName;
                  tmp.birthDate = doc.data().birthDate;
                  tmp.phoneNumber = doc.data().phoneNumber;
                  tmp.credits = doc.data().credits;
                  if(typeof doc.data().creditsD!="undefined")
                  tmp.creditsD = doc.data().creditsD;
                  if(typeof doc.data().creditsLog!="undefined")
                  tmp.creditsLog = doc.data().creditsLog;
                  if(typeof doc.data().logCredits!="undefined")
                  tmp.logCredits = doc.data().logCredits;
                  tmp.gender = doc.data().gender;
                  tmp.user =  db.collection("accounts").doc(doc.id);
                  if(typeof doc.data().call != "undefined")
                  tmp.call = doc.data().call;
                  if(typeof doc.data().xBody != "undefined")
                  tmp.xBody = doc.data().xBody;
                  if(typeof doc.data().gourde != "undefined")
                  tmp.gourde = doc.data().gourde;
                  if(typeof doc.data().note != "undefined")
                  tmp.note = doc.data().note;
                  if(typeof doc.data().bill != "undefined")
                  tmp.bill = doc.data().bill;
                  if(typeof doc.data().role != "undefined")
                  tmp.role = doc.data().role;
                  tmp.uid =  doc.id;
                  tmp.type = "account";
                  tmp.bookings = "";
                  user[doc.id] = tmp;

                  db.collection('usersIndex').doc('users').update(user).then(function(){
                    console.log("Data saved successfully.");
                  }).catch(function(error) {
                    console.log("Data could not be saved." + error);
                  });

              });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });

      db.collection("users").where("role", ">", 2)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  const user = {};
                  const tmp = {};
                  tmp.displayName = doc.data().displayName;
                  tmp.birthDate = doc.data().birthDate;
                  tmp.phoneNumber = doc.data().phoneNumber;
                  tmp.credits = doc.data().credits;
                  if(typeof doc.data().creditsD!="undefined")
                  tmp.creditsD = doc.data().creditsD;
                  if(typeof doc.data().creditsLog!="undefined")
                  tmp.creditsLog = doc.data().creditsLog;
                  tmp.gender = doc.data().gender;
                  tmp.user =  db.collection("users").doc(doc.id);
                  if(typeof doc.data().call != "undefined")
                  tmp.call = doc.data().call;
                  if(typeof doc.data().xBody != "undefined")
                  tmp.xBody = doc.data().xBody;
                  if(typeof doc.data().gourde != "undefined")
                  tmp.gourde = doc.data().gourde;
                  if(typeof doc.data().note != "undefined")
                  tmp.note = doc.data().note;
                  if(typeof doc.data().bill != "undefined")
                  tmp.bill = doc.data().bill;
                  if(typeof doc.data().role != "undefined")
                  tmp.role = doc.data().role;
                  tmp.uid  = doc.id;
                  tmp.type = "user";
                  user[doc.id] = tmp;

                  db.collection('usersIndex').doc('users').update(user).then(function(){
                    console.log("Data saved successfully.");
                  }).catch(function(error) {
                    console.log("Data could not be saved." + error);
                  });

              });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });


          db.collection("users").where("role", "<", 3)
              .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      // doc.data() is never undefined for query doc snapshots
                      const user = {};
                      const tmp = {};
                      tmp.displayName = doc.data().displayName;
                      tmp.birthDate = doc.data().birthDate;
                      tmp.credits = doc.data().credits;
                      if(typeof doc.data().creditsD != "undefined")
                      tmp.creditsD = doc.data().creditsD;
                      tmp.gender = doc.data().gender;
                      tmp.role = doc.data().role;
                      tmp.user =  db.collection("users").doc(doc.id);
                      tmp.type = "user";
                      tmp.uid = doc.id;
                      user['team.' + doc.id] = tmp;

                      db.collection('settings').doc('config').update(user).then(function(){
                        console.log("Data saved successfully.");
                      }).catch(function(error) {
                        console.log("Data could not be saved." + error);
                      });

                  });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });


    }),


    reindexLogs: firestoreAction(() => {

      db.collection("accounts").where("role", ">", 2)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  const user = {};
                  const tmp = {};
                  tmp.displayName = doc.data().displayName;
                  tmp.birthDate = doc.data().birthDate;
                  tmp.credits = doc.data().credits;
                  if(typeof doc.data().credits != "undefined")
                  tmp.credits = doc.data().credits;
                  tmp.creditsLog = doc.data().creditsLog;
                  tmp.gender = doc.data().gender;
                  tmp.logCredits = doc.data().logCredits;
                  tmp.user =  db.collection("accounts").doc(doc.id);
                  tmp.uid =  doc.id;
                  tmp.type = "account";
                  user[doc.id] = tmp;

                                    if(typeof user[doc.id].logCredits != "undefined")
                  db.collection('creditsLogs').doc(doc.id).update(user[doc.id].logCredits).then(function(){
                    console.log("Data saved successfully.");
                  }).catch(function(error) {
                    console.log("Data could not be saved. Creating." + error);
                    db.collection('creditsLogs').doc(doc.id).set(user[doc.id].logCredits).then(function(){
                      console.log(doc.id, user[doc.id].logCredits);
                      console.log("Data saved successfully.");
                    }).catch(function(error) {
                      console.log("Data could not be created." + error);
                    });
                  });

              });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });

      db.collection("users").where("role", ">", 2)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  const user = {};
                  const tmp = {};
                  tmp.displayName = doc.data().displayName;
                  tmp.birthDate = doc.data().birthDate;
                  tmp.credits = doc.data().credits;
                  if(typeof doc.data().creditsD != "undefined")
                  tmp.creditsD = doc.data().creditsD;
                  tmp.creditsLogs = doc.data().creditsLogs;
                  tmp.gender = doc.data().gender;
                  tmp.user =  db.collection("users").doc(doc.id);
                  tmp.uid  = doc.id;
                  tmp.type = "user";
                  tmp.logCredits = doc.data().logCredits;
                  user[doc.id] = tmp;

                  if(typeof user[doc.id].logCredits != "undefined")
                  db.collection('creditsLogs').doc(doc.id).update(user[doc.id].logCredits).then(function(){
                    console.log("Data saved successfully.");
                  }).catch(function(error) {
                    console.log("Data could not be saved. Creating" + error);

                    db.collection('creditsLogs').doc(doc.id).set(user[doc.id].logCredits).then(function(){
                      console.log(doc.id, user[doc.id].logCredits);
                      console.log("Data saved successfully.");
                    }).catch(function(error) {
                      console.log("Data could not be created." + error);
                    });
                  });

              });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });


    }),

    log: firestoreAction((context, payload) => {

      const date = (new Date(firebase.firestore.Timestamp.now().seconds * 1000)).toISOString().slice(0,10);
      const data = {};
      data[(new Date(firebase.firestore.Timestamp.now().seconds * 1000)).getTime()] = payload;

      db.collection('logs').doc(date).update(data).then(function(){
        console.log("log: Data saved successfully.");
      }).catch(function(error) {
        console.log("log: Data could not be updated. Setting data" + error);
        db.collection('logs').doc(date).set(data)
      });



    }),
    /* changeLog: firestoreAction((payload) => {

      const uid = payload.user;
      const credits = payload.credits;
      let oldcredits = 0;
      let iterator = payload.iterator;
      const creditsLog = {};

      console.log('changeLog',uid, credits, iterator);

      console.log('payload',payload);

      let collection = 'users';
      if(!isNaN(uid)){ collection = 'accounts';}
      if(uid!=null)
        db.collection(collection).doc(uid).get().then((doc)=>{

          const data = doc.data();
          if(typeof data!="undefined") {

          let key = null;

          for(const i in data.creditsLog){
            key = i;
            oldcredits = data.creditsLog[i];
          }

          const user  = {};
          if(key!=null)
          if(credits!=null) {
            creditsLog[key] = credits;
          } else {
            creditsLog[key] = oldcredits;
          }
          if(credits!=null) user[uid+'.creditsLog'] = creditsLog;

          //data iterator is user iterator , iterator was sent to this
          if(typeof data.iterator != "undefined" && data.iterator != null){
            if(data.iterator < iterator) {
              //test
            } else {
              iterator = data.iterator;
            }
            user[uid+'.iterator'] = iterator;
          } else {
              if(iterator!=null)
                iterator = data.iterator;
              else
                iterator = 1;
              user[uid+'.iterator'] = iterator;
          }
          if(typeof iterator == "undefined") {
            iterator = 2;
            user[uid+'.iterator'] = 2;
          }

          db.collection(collection).doc(uid).update( {iterator: iterator, creditsLog: creditsLog});
          db.collection('usersIndex').doc('users').update( user );
          }
        });

    }),*/
    addNotifications: firestoreAction((context,payload) => {

      const update = {};
      update.notifications = {};
      const date = new Date(firebase.firestore.Timestamp.now().seconds * 1000);
      let text = 'notification';
      let link = null;
      let uid = null;
      if(typeof payload.text != "undefined" && payload.text != null) text = payload.text;
      if(typeof payload.link != "undefined" && payload.link != null) link = payload.link;
      if(typeof payload.uid != "undefined" && payload.uid != null) uid = payload.uid;
      if(link == null)
        update.notifications[date.getTime()] = { text: text, uid: uid };
      else
        update.notifications[date.getTime()] = { text: text, link: link, uid: uid };
/*
      db.collection('users').doc(payload.user).set(update, {merge: true});
      context.dispatch("reindexUser", payload.user);
*/
    }),
    markAsCalled: firestoreAction((context,payload) => {

      console.log('markAsCalled', payload);

        const uid = payload.user;
        const uid2 = payload.user2;

        let collection = 'users';
        if(!isNaN(uid)){ collection = 'accounts';}

        let collection2 = 'users';
        if(!isNaN(uid2)){ collection2 = 'accounts';}

        const time = firebase.firestore.Timestamp.fromDate(new Date(payload.date));

        const user  = {};
        user[uid+'.call'] = time;


        const user2  = {};
        user2[uid2+'.call'] =  time;

        db.collection(collection).doc(uid).get().then(()=>{

          db.collection(collection).doc(uid).update( {call:  time});
          db.collection('usersIndex').doc('users').update( user );

        });
        if(uid2!=null)
        db.collection(collection2).doc(uid2).get().then(()=>{

          db.collection(collection2).doc(uid2).update( {call:  time});
          db.collection('usersIndex').doc('users').update( user2 );

        });
    }),
    deleteNotifications: firestoreAction((context,payload) => {

      console.log('deleteNotifications');

      db.collection('users').doc(payload.user).update( {notifications: null});

      context.commit("setNotifications", null);

      context.dispatch("reindexUser", payload.user);

    }),
    addCredit: firestoreAction((context,payload) => {

      /* const user = payload.user; */
      if(payload.user != null) {
        if(!isNaN(payload.user)){

          const user  = {};
          user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1);
          db.collection('accounts').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1)});


          // fdb.collection('creditsHistory').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1)});

        } else {
          const user  = {};
          user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1);
          db.collection('users').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1)});
        }
      }

      context.dispatch("reindexUser", payload.user);

    }),
    addCreditD: firestoreAction((context,payload) => {

      /* const user = payload.user; */
      console.log('addCreditD', payload.user, isNaN(payload.user));

      if(!isNaN(payload.user)){
        const user  = {};
        user[payload.user+'.creditsD'] = firebase.firestore.FieldValue.increment(1);
        db.collection('accounts').doc(payload.user).update( {creditsD: firebase.firestore.FieldValue.increment(1)});

      } else {
        const user  = {};
        user[payload.user+'.creditsD'] = firebase.firestore.FieldValue.increment(1);
        db.collection('users').doc(payload.user).update( {creditsD: firebase.firestore.FieldValue.increment(1)});
      }
      if(typeof payload.user2 != "undefined" && payload.user2 != null) {
      if(!isNaN(payload.user2)){
        const user2  = {};
        user2[payload.user2+'.creditsD'] = firebase.firestore.FieldValue.increment(1);
        db.collection('accounts').doc(payload.user2).update( {creditsD: firebase.firestore.FieldValue.increment(1)});

      } else {
        const user2  = {};
        user2[payload.user+'.creditsD'] = firebase.firestore.FieldValue.increment(1);
        db.collection('users').doc(payload.user2).update( {creditsD: firebase.firestore.FieldValue.increment(1)});
      }
      }


      context.dispatch("reindexUser", payload.user);
      if(typeof payload.user2 != "undefined" && payload.user2 != null) context.dispatch("reindexUser", payload.user2);

    }),
    saveDisclaimer: firestoreAction((context,payload) => {
      console.log(payload);

      db.collection('settings').doc('config').update( {disclaimer: payload});
      /* const user = payload.user;
      console.log('addCredit', payload.user, isNaN(payload.user));

      if(!isNaN(payload.user)){
        const user  = {};
        user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1);
        db.collection('accounts').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1)});

      } else {
        const user  = {};
        user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1);
        db.collection('users').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1)});
      }

      context.dispatch("reindexUser", payload.user);
      */
    }),

    saveMessage: firestoreAction((context,payload) => {

      console.log('addMessage', payload.user, isNaN(payload.user));

      const update = {};
      update.notifications = {};
      /*
      const date = new Date(firebase.firestore.Timestamp.now().seconds * 1000);
      let text = '';
      let link = null;
      let uid = null;
      if(typeof payload.message.Objec.text != "undefined" && payload.message.text != null) text = payload.message.text;
      if(typeof payload.message.link != "undefined" && payload.message.link != null) link = payload.message.link;
      if(typeof payload.user != "undefined" && payload.user != null) uid = payload.user;
      if(link == null)
        update.notifications[date.getTime()] = { text: text, uid: uid };
      else
        update.notifications[date.getTime()] = { text: text, link: link, uid: uid };*/
        payload.message[Object.keys(payload.message)].uid = payload.admin;
        update.notifications = payload.message;

        console.log(update);
      if(!isNaN(payload.user)){
        db.collection('users').doc(payload.user).set(update, {merge: true});

      } else {
        db.collection('users').doc(payload.user).set(update, {merge: true});


      }

      context.dispatch("reindexUser", payload.user);

    }),

    deleteMessages: firestoreAction((context,payload) => {

      console.log('deleteNotifications', payload);

      db.collection('users').doc(payload.user).update( {notifications: null});

      context.commit("setNotifications", null);

      context.dispatch("reindexUser", payload.user);

    }),
    removeCredit: firestoreAction((context, payload) => {
        console.log(payload);
        /* const user = payload.user; */
        console.log('removeCredit', payload.user, isNaN(payload.user))
        if(payload.user!=null) {
          if(!isNaN(payload.user)){
            const user  = {};
            user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1-2);
            db.collection('accounts').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1-2)});
            db.collection('usersIndex').doc('users').update( user );
          } else {
            const user  = {};
            user[payload.user+'.credits'] = firebase.firestore.FieldValue.increment(1-2);
            db.collection('users').doc(payload.user).update( {credits: firebase.firestore.FieldValue.increment(1-2)});
            db.collection('usersIndex').doc('users').update( user );
          }
        }

    }),

    removeCreditD: firestoreAction((context, payload) => {
        console.log(payload);
        /* const user = payload.user; */
        console.log('removeCreditD', payload.user, isNaN(payload.user))
        console.log('removeCreditD', payload.user2, isNaN(payload.user2))

        if(!isNaN(payload.user)){
          const user  = {};
          user[payload.user+'.creditsD'] = firebase.firestore.FieldValue.increment(1-2);
          db.collection('accounts').doc(payload.user).update( {creditsD: firebase.firestore.FieldValue.increment(1-2)});
          db.collection('usersIndex').doc('users').update( user );
        } else {
          const user  = {};
          user[payload.user+'.creditsD'] = firebase.firestore.FieldValue.increment(1-2);
          db.collection('users').doc(payload.user).update( {creditsD: firebase.firestore.FieldValue.increment(1-2)});
          db.collection('usersIndex').doc('users').update( user );
        }

        if(typeof payload.user2 != "undefined" && payload.user2 != null)
        if(!isNaN(payload.user2)){
          const user2  = {};
          user2[payload.user2+'.creditsD'] = firebase.firestore.FieldValue.increment(1-2);
          db.collection('accounts').doc(payload.user2).update( {creditsD: firebase.firestore.FieldValue.increment(1-2)});
          db.collection('usersIndex').doc('users').update( user2 );
        } else {
          const user2  = {};
          user2[payload.user2+'.creditsD'] = firebase.firestore.FieldValue.increment(1-2);
          db.collection('users').doc(payload.user2).update( {creditsD: firebase.firestore.FieldValue.increment(1-2)});
          db.collection('usersIndex').doc('users').update( user2 );
        }
    }),

    saveBill: firestoreAction((context,payload) => {
      console.log('saveBill',payload)
      const uid = payload.user;

      let collection = 'users';
      if(!isNaN(uid)){ collection = 'accounts';}

      const update = {};

      const user  = {};

      if(typeof payload.bill != "undefined") {
      user[uid+'.bill'] = payload.bill;
      update.bill = payload.bill;
      }

      db.collection(collection).doc(uid).get().then(()=>{
        console.log(update, user);
        /*const data = doc.data();*/
        db.collection(collection).doc(uid).update( update );
        db.collection('usersIndex').doc('users').update( user );

      });

    }),

    saveStats: firestoreAction((context, payload) => {
      console.log('saveStats',payload)
      const uid = payload.user;

      let collection = 'users';
      if(!isNaN(uid)){ collection = 'accounts';}

      const update = {};

      const user  = {};
      if(typeof payload.gourde != "undefined") {
        user[uid+'.gourde'] = payload.gourde;
        update.gourde = payload.gourde;
      }
      if(typeof payload.xBody != "undefined") {
      user[uid+'.xBody'] = payload.xBody;
      update.xBody = payload.xBody;
      }
      if(typeof payload.call != "undefined") {
      user[uid+'.call'] = payload.call;
      update.call = payload.call;
      }
      if(typeof payload.note != "undefined") {
      user[uid+'.note'] = payload.note;
      update.note = payload.note;
      }
      if(typeof payload.bill != "undefined") {
      user[uid+'.bill'] = payload.bill;
      update.bill = payload.bill;
      }
      if(typeof payload.fiche != "undefined") {
      user[uid+'.fiche'] = payload.fiche;
      update.fiche = payload.fiche;
      }

      db.collection(collection).doc(uid).get().then(()=>{
        console.log(update);
        /*const data = doc.data();*/
        db.collection(collection).doc(uid).update( update );
        db.collection('usersIndex').doc('users').update( user );

      });

    }),
    saveCredit: firestoreAction((context, payload) => {
      const uid = payload.user;
      const credits = payload.credits;
      const creditsLog = {};
      console.log(payload.admin, payload.admin.id);
      const logAdmin = payload.admin;


      let collection = 'users';
      if(!isNaN(uid)){ collection = 'accounts';}

        db.collection(collection).doc(uid).get().then((doc)=>{

          const data = doc.data();

          let old = 0;
          if(typeof data != "undefined" && typeof data['credits'] != "undefined")
            old = data['credits'];

          const user  = {};
          user[uid+'.credits'] = credits;
          const date = (new Date(firebase.firestore.Timestamp.now().seconds * 1000)).toISOString().slice(0, 10);
          const log = {};
          const hour = {};
          const today = new Date(firebase.firestore.Timestamp.now().seconds * 1000);
          const time = today.getHours().toString().padStart(2,0) + ":" + today.getMinutes().toString().padStart(2,0) + ":" + today.getSeconds().toString().padStart(2,0);
          hour[time] =  credits - old
          log[date] = hour;

          creditsLog[date] = credits;
          user[uid+'.creditsLog'] = creditsLog;

          let type = {};
          type['solo'] = log;


          if((credits-old)!=0) {
            db.collection(collection).doc(uid).update( {credits: credits, creditsLog: creditsLog});
            db.collection("creditsLogs").doc(uid).set(log, {merge: true});
            db.collection("creditsHistory").doc(uid).set(type, {merge: true});
            db.collection('usersIndex').doc('users').update( user );

            let diff = credits - old;
            if(diff>0) diff = "+" + diff;

            let logUser = uid; /* ok */
            let logUser2 = null;
            let logGroup = false;
            let logCoach = null;
            let logData  = {
              msg : "modification de crédit " + "(" + diff + ")"  + " " + time + " f(de " + old+ " à "+ credits +")",
              user : logUser,
              admin : logAdmin,
              group : logGroup,
              user2 : logUser2,
              coach : logCoach,
              }
            context.dispatch("log", logData);
          }
        });

    }),
    saveCreditD: firestoreAction((context, payload) => {
      const uid = payload.user;
      const credits = payload.creditsD;
      const creditsLog = {};
      console.log(payload.admin, payload.admin.id);
      const logAdmin = payload.admin;


      let collection = 'users';
      if(!isNaN(uid)){ collection = 'accounts';}

        db.collection(collection).doc(uid).get().then((doc)=>{

          const data = doc.data();

          let old = 0;
          if(typeof data != "undefined" && typeof data['credits'] != "undefined")
            old = data['creditsD'];

          const user  = {};
          user[uid+'.creditsD'] = credits;
          const date = (new Date(firebase.firestore.Timestamp.now().seconds * 1000)).toISOString().slice(0, 10);
          const log = {};
          const hour = {};
          const today = new Date(firebase.firestore.Timestamp.now().seconds * 1000);
          const time = today.getHours().toString().padStart(2,0) + ":" + today.getMinutes().toString().padStart(2,0);
          hour[time] =  credits - old
          log[date] = hour;

          creditsLog[date] = credits;
          user[uid+'.creditsLog'] = creditsLog;

          let type = {};
          type['duo'] = log

          if((credits-old)!=0) {
            db.collection(collection).doc(uid).update( {creditsD: credits, creditsLog: creditsLog});
            db.collection("creditsLogs").doc(uid).set(log, {merge: true});
            db.collection("creditsHistory").doc(uid).set(type, {merge: true});
            db.collection('usersIndex').doc('users').update( user );

            let diff = credits - old;
            if(diff>0) diff = "+" + diff;

            let logUser = uid; /* ok */
            let logUser2 = null;
            let logGroup = false;
            let logCoach = null;
            let logData  = {
              msg : "modification de crédit duo " + "(" + diff + ")"  + " " + time + " f(de " + old+ " à "+ credits +")",
              user : logUser,
              admin : logAdmin,
              group : logGroup,
              user2 : logUser2,
              coach : logCoach,
              }
            context.dispatch("log", logData);
          }
        });

    }),
    bindBookings: firestoreAction(({ bindFirestoreRef },payload) => {

      let start = moment(firebase.firestore.Timestamp.now().seconds * 1000);
      let end = moment(firebase.firestore.Timestamp.now().seconds * 1000);

      /*
      let plusone = false;

      if(end.day()==0) {
        plusone = true;
       end.setDate(end.getDate() + 2);
     }*/
      if(typeof payload != "undefined" && typeof payload.start != "undefined" && typeof payload.end != "undefined") {

        start = moment(payload.start);
        end = moment(payload.end);
        //end.setDate(end.date() + 1);
        end = end.add(1,'days');

      } else {

        /* const day = start.day(); */
        const prevMonday = moment(firebase.firestore.Timestamp.now().seconds * 1000);
        /* if(start.day() == 0)
            prevMonday.add(start.date() - 6);
        else
            prevMonday.add(start.date() - (day));
        */
        start = prevMonday.startOf('Week');
      /*  if(plusone)
          end.add(1 + ((7-end.day())) % 7);
        else
          end.add(((7-end.day())) % 7);
          */
      }

      start = start.startOf('Week');
      end = end.endOf('Week');
/*      start.setMinutes(0);
      end.setMinutes(0);
      start.setSeconds(0);
      end.setSeconds(0);
      */

       // eslint-disable-next-line
      start = new Date(start.valueOf());
       // eslint-disable-next-line
      end = new Date(end.valueOf());

      return bindFirestoreRef('bookings', db.collection('bookings').where('date', '>=', start).where('date', '<=', end));
    }),
    bindTeamBookings: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('bookings', db.collection('bookings').limit(31))
    }),
    deleteBooking: firestoreAction((context, payload) => {

      const data = {}
      const container = {}
      const del = {}
      let tmp = null;

      /* remove booking from user */

      const bookingUser = payload.user;
      const bookingUser2 = payload.user2;
      console.log('deleteBooking', bookingUser);
      if(bookingUser!=null)
      db.collection('usersLogs').doc(bookingUser).get().then((doc)=>{

        const data = doc.data();

        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && typeof data[payload.date][payload.time] != 'undefined' )
          delete data[payload.date][payload.time];
        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && Object.keys(data[payload.date]).length==0)
          delete data[payload.date];

          if(payload.group == null || !payload.group)
            context.dispatch("addCredit", {user: bookingUser});
          else
            context.dispatch("addCreditD", {user: bookingUser, user2: bookingUser2});


        if(typeof data != 'undefined' ) {
        db.collection('usersLogs').doc(bookingUser).set( data );
      }
        if(typeof data != 'undefined' ) {
        db.collection('usersLogs').doc(bookingUser2).set( data );
      }
      });
      /*
      if(bookingUser2!=null)
      db.collection('usersLogs').doc(bookingUser2).get().then((doc)=>{

        const data = doc.data();

        if(typeof data != 'undefined' && typeof data[payload.date]!='undefined' && typeof data[payload.date][payload.time]!='undefined')
          delete data[payload.date][payload.time];
        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && Object.keys(data[payload.date]).length==0)
          delete data[payload.date];
        if(typeof data != 'undefined' && bookingUser2!=null) {
        db.collection('usersLogs').doc(bookingUser2).set( data );
        }

               context.dispatch("addCredit", {user: bookingUser2});
      });
      */


      /* remove booking from bookings */

      payload.confirmed = false
      /* let user = null;
      let user2 = null;
      user = payload.user;
      user2 = payload.user2;*/
      payload.user=null
      payload.user2=null
      payload.title="";
      payload.floor= 0;
      payload.time = payload.time.slice(0,5)
      const coach = {};
      tmp =payload.coach;
      delete payload.coach

      container[tmp] = payload
      data[payload.time] = container
      del[payload.time + "." + tmp] = firebase.firestore.FieldValue.delete();

      db.collection('bookings').doc(payload.date).get().then((doc)=>{

         let update = false;
         let bookings = {};
         bookings = doc.data();
         for(const i in bookings) {
           if(i == payload.time) {
             for (const j in bookings[i]) {
                coach[j] = bookings[i][j];
               if(j == tmp) {
                 update = true;
                 coach[j] = payload;
               }
             }
           } else
               data[i] = bookings[i];
         }
         if(!update) coach[tmp] = payload;
         data[payload.time] = coach;

         db.collection("bookings").doc(payload.date).update(data).then(() => {
           db.collection("bookings").doc(payload.date).update( del )

         });

      }).catch((error) => {
        console.error("Error saving", error);
      });

    }),
    deleteOldBooking: firestoreAction((context, payload) => {

      const data = {}
      const container = {}
      const del = {}
      let tmp = null;

      /* remove booking from user */

      const bookingUser = payload.user;
      const bookingUser2 = payload.user2;

      /* let collection = 'users';
      if(!isNaN(payload.user)) collection = 'accounts'; */

      if(bookingUser!=null)
      db.collection('usersLogs').doc(bookingUser).get().then((doc)=>{

        const data = doc.data();

        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && typeof data[payload.date][payload.time] != 'undefined' )
          delete data[payload.date][payload.time];
        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && Object.keys(data[payload.date]).length==0)
          delete data[payload.date];

        if(payload.group==null || !payload.group)
          context.dispatch("addCredit", {user: bookingUser});
        else
          context.dispatch("addCreditD", {user: bookingUser, user2: bookingUser2 });

        if(typeof data != 'undefined' ) {
        /* db.collection(collection).doc(bookingUser).update( data );*/
        db.collection('usersLogs').doc(bookingUser).set( data );
      }
/*

                console.log('db.collection("users").doc', payload.user);
                const user  = {};
                const copy = data;
                if(typeof copy != "undefined")
                copy["bookings"] = {};
                user[bookingUser] = copy;
                console.log(user)
                db.collection('usersIndex').doc('users').update( user );
*/
      });

/*      let collection2 = 'users';
      if(!isNaN(payload.user2)) collection2 = 'accounts';*/

      /*
      if(bookingUser2!=null)
      db.collection('usersLogs').doc(bookingUser2).get().then((doc)=>{

        const data = doc.data();


        if(typeof data != 'undefined' && typeof data[payload.date]!='undefined' && typeof data[payload.date][payload.time]!='undefined')
          delete data[payload.date][payload.time];
        if(typeof data != 'undefined' && typeof data[payload.date] != 'undefined' && Object.keys(data[payload.date]).length==0)
          delete data[payload.date];


        if(typeof data != 'undefined') {
        db.collection('usersLogs').doc(bookingUser2).set( data );
        }

        context.dispatch("addCredit", {user: bookingUser2});

      });
      */

      /* remove booking from bookings */

      payload.confirmed = false
/*      let user = null;
      let user2 = null;
      user = payload.user;
      user2 = payload.user2;*/
      payload.user=null
      payload.user2=null
      payload.title="";
      payload.floor= 0;
      payload.time = payload.time.slice(0,5)
      const coach = {};
      tmp =payload.coach;
      delete payload.coach

      container[tmp] = payload
      data[payload.time] = container
      del[payload.time + "." + tmp] = firebase.firestore.FieldValue.delete();

      db.collection('bookings').doc(payload.date).get().then((doc)=>{

         let update = false;
         let bookings = {};
         bookings = doc.data();
         for(const i in bookings) {
           if(i == payload.time) {
             for (const j in bookings[i]) {
                coach[j] = bookings[i][j];
               if(j == tmp) {
                 update = true;
                 coach[j] = payload;
               }
             }
           } else
               data[i] = bookings[i];
         }
         if(!update) coach[tmp] = payload;
         data[payload.time] = coach;

         db.collection("bookings").doc(payload.date).update(data).then(() => {
           db.collection("bookings").doc(payload.date).update( del )

         });

      }).catch((error) => {
        console.error("Error saving", error);
      });

    }),
    saveTimetable: firestoreAction(() => {
      const timetable = {
      1 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      2 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      3 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      4 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      5 : [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30"}, {hour: "16:00"}, {hour: "16:30"}, {hour: "17:00"}, {hour: "17:30"},
          {hour: "18:00"}, {hour: "18:30"}, {hour: "19:00"}, {hour: "19:30"}, {hour: "20:00"}, {hour: "20:30"},
          {hour: "21:00"}, {hour: "21:30"}, {hour: "22:00"}, {hour: "22:30"}, {hour: "23:00"}, {hour: "23:30"}
      ],
      6: [
          {hour: "00:00"}, {hour: "00:30"}, {hour: "01:00"}, {hour: "01:30"}, {hour: "02:00"}, {hour: "02:30"},
          {hour: "03:00"}, {hour: "03:30"}, {hour: "04:00"}, {hour: "04:30"}, {hour: "05:00"}, {hour: "05:30"},
          {hour: "06:00"}, {hour: "06:30"}, {hour: "07:00"}, {hour: "07:30"}, {hour: "08:00"}, {hour: "08:30"},
          {hour: "09:00"}, {hour: "09:30"}, {hour: "10:00"}, {hour: "10:30"}, {hour: "11:00"}, {hour: "11:30"},
          {hour: "12:00"}, {hour: "12:30"}, {hour: "13:00"}, {hour: "13:30"}, {hour: "14:00"}, {hour: "14:30"},
          {hour: "15:00"}, {hour: "15:30", unavailable: true}, {hour: "16:00", unavailable: true}, {hour: "16:30", unavailable: true}, {hour: "17:00", unavailable: true}, {hour: "17:30", unavailable: true},
          {hour: "18:00", unavailable: true}, {hour: "18:30", unavailable: true}, {hour: "19:00", unavailable: true}, {hour: "19:30", unavailable: true}, {hour: "20:00", unavailable: true}, {hour: "20:30", unavailable: true},
          {hour: "21:00", unavailable: true}, {hour: "21:30", unavailable: true}, {hour: "22:00", unavailable: true}, {hour: "22:30", unavailable: true}, {hour: "23:00", unavailable: true}, {hour: "23:30", unavailable: true}
      ],
      0 : [
          /* Free sunday */
      ]
      }

                   db.collection("settings").doc('week').update(timetable);

    }),
    updateCalendar: firestoreAction((context, payload) => {

        const data = {}
/*        const coach = {}
        const time = {}*/

        //payload.booking = null;
        payload.time = payload.time.slice(0,5);

        db.collection('settings').doc('week').get().then((doc)=>{

           if(doc.exists){

             let bookings = {};
             bookings = doc.data();

             const dt = new Date(payload.date);

/*             let update = false; */
             for(const i in bookings[dt.getDay()]) {
               if(bookings[dt.getDay()][i].hour == payload.time) {
                   /* update = true; */
                   //if(typeof bookings[i][j].user != "undefined") updateUser = bookings[i][j].user;
                   if(typeof bookings[dt.getDay()][i].booked == "undefined") {
                     const coach = {} ;
                     coach[payload.coach] = true;
                     bookings[dt.getDay()][i].booked = coach;
                   } else {
                     if( typeof bookings[dt.getDay()][i]["booked"][payload.coach] != "undefined"
                     && !bookings[dt.getDay()][i]["booked"][payload.coach]==false)
                      bookings[dt.getDay()][i]["booked"][payload.coach] = false
                     else
                      bookings[dt.getDay()][i]["booked"][payload.coach] = true
                   }

                   data[i] = bookings[dt.getDay()][i];
               } else
                  data[i] = bookings[dt.getDay()][i];
             }


            const upd = {} ;
            upd[dt.getDay()] = data;

             db.collection("settings").doc('week').update(upd);

           }

         });

    }),
    updateCalendarUser: firestoreAction((context, payload) => {

        const data = {}/*
        const coach = {}
        const time = {}*/

        //payload.booking = null;
        payload.time = payload.time.slice(0,5);

        let collection = 'users';
        if(!isNaN(payload.user)) collection = 'accounts';

        db.collection('settings').doc('week').get().then((doc)=>{

           if(doc.exists){

             let bookings = {};
             bookings = doc.data();

             const dt = new Date(payload.date);

             /* let update = false; */
             for(const i in bookings[dt.getDay()]) {
               if(bookings[dt.getDay()][i].hour == payload.time) {
                   /* update = true; */
                   //if(typeof bookings[i][j].user != "undefined") updateUser = bookings[i][j].user;
                   if(typeof bookings[dt.getDay()][i].booked == "undefined") {
                     const coach = {} ;
                     coach[payload.coach] = true;
                     bookings[dt.getDay()][i].booked = coach;
                   } else {
                     if( typeof bookings[dt.getDay()][i]["booked"][payload.coach] != "undefined"
                     && !bookings[dt.getDay()][i]["booked"][payload.coach]==false)
                      bookings[dt.getDay()][i]["booked"][payload.coach] = db.collection(collection).doc(payload.user)
                   }

                   data[i] = bookings[dt.getDay()][i];
               } else
                  data[i] = bookings[dt.getDay()][i];
             }


            const upd = {} ;
            upd[dt.getDay()] = data;

             db.collection("settings").doc('week').update(upd);

           }

         });

    }),
    updateBooking: firestoreAction((context, payload) => {


        const data = {}
        const coach = {}
        const time = {}
        if(typeof payload.confirmed == "undefined" || payload.confirmed == null) payload.confirmed = false;

        console.log('updateBooking', payload);

        if(payload.user !=null) {
          if(!isNaN(payload.user))
            payload.booking = db.collection('accounts').doc(payload.user);
          else
            payload.booking = db.collection('users').doc(payload.user);
        }
        //payload.booking = null;
        payload.time = payload.time.slice(0,5);

        console.log('updateBookingDate', payload.date, typeof payload.date);

        db.collection('bookings').doc(payload.date).get().then((doc)=>{

           if(doc.exists) {
             /* si il existe au moins un booking à cette date */

            delete payload.credits

             let bookings = {};
             bookings = doc.data();

              /* Test pour voir si le créneau est occupé. Fin d'opération si c'est une demande utilisateur */
             let security = 0;
             console.log(bookings[payload.time]);
             if(typeof bookings[payload.time] != "undefined") security++;
             if(typeof bookings[payload.time] != "undefined" && typeof bookings[payload.time][payload.coach] != "undefined") security++;
             if(typeof bookings[payload.time] != "undefined"
             && typeof bookings[payload.time][payload.coach] != "undefined"
             && typeof bookings[payload.time][payload.coach].color != "undefined"
             && bookings[payload.time][payload.coach].color == "white") security--;
             if(payload.from==3) security++;
             if(security==3) {
               console.log('Illegal operation.');

               let logUser = payload.user; /* ok */
               let logUser2 = payload.user2;
               /* let logAdmin = this.getCurrentUser().uid; */
               let logCoach = payload.coach;
               let logData  = {
                 msg : "Annulation (auto.) de demande réservation " + payload.date + " " + payload.time,
                 user : logUser,
                 admin : logUser,
                 user2 : logUser2,
                 coach : logCoach,
                 }
               context.dispatch("log", logData);

               return false;
             }

             /* le créneau existe, et est consulté par un admin */


           /* Est-ce qu'il s'agit d'un créneau existant avec les membres concernés ? */
          let update = false;
          let updateUser = null;
          let updateUser2 = null;
          let updateGroup = null;

          for(const i in bookings) {
            if(i == payload.time) {
              for (const j in bookings[i]) {
                  coach[j] = bookings[i][j];
                  if(j == payload.coach) {
                    update = true;
                    if(typeof bookings[i][j].user != "undefined") updateUser = bookings[i][j].user;
                    if(typeof bookings[i][j].user2 != "undefined") updateUser2 = bookings[i][j].user2;
                    if(typeof bookings[i][j].group != "undefined") updateGroup = bookings[i][j].group;
                    coach[j] = payload;
                  }
              }
            } else
            data[i] = bookings[i];
          }

          /* Réservation d'un créneau */
          if(payload.group == null || !payload.group) {
            if(!update && payload.user!=null) {
                context.dispatch("removeCredit", {user: payload.user});
            }
          }

          /* Réservation d'un créneau en duo */
          if(payload.group !=null && payload.group) {
            if(!update && payload.user!=null) {
                context.dispatch("removeCreditD", {user: payload.user, user2: payload.user2});
            }
          }

          /* Modification d'un créneau */
          if(update) {

            if(updateGroup && payload.group) {
              // Le créneau reste duo

              // Ajout de deux membres sur un créneau duo existant
              if(updateUser == null && updateUser2 == null) {
                context.dispatch("removeCreditD", {user: payload.user, user2: payload.user2});
              }

            }
            if(!updateGroup && (payload.group == null || !payload.group)) {
              // Le créneau reste solo

              // Ajout d'un membre sur un créneau simple existant
              if(updateUser == null) context.dispatch("removeCredit", {user: payload.user});
            }
            if(updateGroup && (payload.group == null || !payload.group))
            {
              // Le créneau duo devient solo
              if(updateUser != null) {
                context.dispatch("removeCredit", {user: payload.user});
                context.dispatch("addCreditD", {user: updateUser, user2:updateUser2});
              }
            }
            if(!updateGroup && payload.group)
            {
              // Le créneau solo devient duo

              // Ajout d'un membre à un créneau simple existant, devient duo
              if(updateUser != null && updateUser2 == null) {
                context.dispatch("addCredit", {user: payload.user});
                context.dispatch("removeCreditD", {user: payload.user, user2:payload.user2});
              }
            }


          }

         if(!update) coach[payload.coach] = payload;
         data[payload.time] = coach;

         data["date"] = new Date(payload.date);
         db.collection("bookings").doc(payload.date).update(data);

           } else {

             /* si il n'y a pas de booking à cette date */

             time[payload.coach] = payload;
             data[payload.time] = time;

             if(payload.group!=null && payload.group) {
               if(payload.user  !=null) context.dispatch("removeCreditD", {user: payload.user, user2: payload.user2});
             } else {
               if(payload.user  !=null) context.dispatch("removeCredit", {user: payload.user});
             }

              data["date"] = new Date(payload.date);
              db.collection("bookings").doc(payload.date).set(data);

           }

           /* Ajout du créneau dans le compte du membre 1 */
           if(payload.user!=null) {
            const userSave = {};
            const userDate = {};
            const userTime = {};
            const userCoach = {};
            userCoach[payload.coach] = payload
            userTime[payload.time] = userCoach
            userDate[payload.date] = userTime;
            userSave['bookings'] = userDate;

           console.log('userDate',userDate);

            db.collection("usersLogs").doc(payload.user).set(userDate, {merge: true});

           }

          /* Ajout du créneau dans le compte du membre 2 */
          if(payload.user2!=null) {
           const userSave = {};
           const userDate = {};
           const userTime = {};
           const userCoach = {};
           userCoach[payload.coach] = payload
           userTime[payload.time] = userCoach
           userDate[payload.date] = userTime;
           userSave['bookings'] = userDate;

           db.collection("usersLogs").doc(payload.user2).set(userDate, {merge: true});

          }

         });

    }),

    bindUsersIndex: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('usersIndex', db.collection('usersIndex').doc('users'))
    }),
    bindLogs: firestoreAction(({ bindFirestoreRef },payload) => {
      let date = new Date().toISOString().slice(0, 10);
      if(typeof payload != "undefined") {
        date = new Date(payload).toISOString().slice(0, 10);
      }
      return bindFirestoreRef('logs', db.collection('logs').doc(date))
    }),
    bindTeam: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('team', db.collection('users').where("role", "<", 3))
    }),
    bindUsers: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('users', db.collection('users'))
    }),
    addUser: firestoreAction((context, payload) => {
        return db.collection('users').add(payload)
    }),
    deleteManager: firestoreAction((context, payload) => {

      // db.collection('users').doc(payload).delete()
      const docRef = db.collection("settings").doc('config');
      //const docRef = db.collection("users").doc("Xuv3q8rbFPNxFwiE9KQ1cp2l6843");
      docRef.get().then((doc) => {
          if (doc.exists) {
              const data = doc.data()
              if(typeof data!="undefined") {
              data.team = payload.coachs;
              delete data.team[payload.coach];
              db.collection("settings").doc('config').update(data);
              }
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
        db.collection('users').doc(payload.coach).update({role:3})

  //    db.collection("settings").doc('config').update(timetable);
    }),
    
    saveManagers: firestoreAction((context, payload) => {

      // db.collection('users').doc(payload).delete()
      const docRef = db.collection("settings").doc('config');
      //const docRef = db.collection("users").doc("Xuv3q8rbFPNxFwiE9KQ1cp2l6843");
      docRef.get().then((doc) => {
          if (doc.exists) {
              const data = doc.data()
              if(typeof data!="undefined") {
              data.team = payload.coachs;
              db.collection("settings").doc('config').update(data);
              }
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
      /* const role = {}; */

//    db.collection("settings").doc('config').update(timetable);
  }),
    addManager: firestoreAction((context, payload) => {

      // db.collection('users').doc(payload).delete()
      const docRef = db.collection("settings").doc('config');
      //const docRef = db.collection("users").doc("Xuv3q8rbFPNxFwiE9KQ1cp2l6843");
      docRef.get().then((doc) => {
          if (doc.exists) {
              const data = doc.data()
              if(typeof data!="undefined") {
                
              if(typeof payload.data!="undefined") payload.data.role = 2;
              data.team[payload.coach] = payload.data;
              console.log(payload.coach, payload.data);
              db.collection("settings").doc('config').set(data, {merge: true});
              }
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
      /* const role = {}; */
      db.collection('users').doc(payload.coach).update({role:2})

//    db.collection("settings").doc('config').update(timetable);
  }),
    deleteUser: firestoreAction((context, payload) => {
        db.collection('users').doc(payload).delete()
    }),
    removeUser: firestoreAction((context, payload) => {
      if(isNaN(payload.user)){
        db.collection("accounts").doc(payload.toString()).delete().then(() => {
            console.log("Document successfully deleted! accounts");

            const user  = {};
            user[payload.toString()] = firebase.firestore.FieldValue.delete();
            db.collection('usersIndex').doc('users').update( user );

        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      } else {
        db.collection("users").doc(payload.toString()).delete().then(() => {
            console.log("Document successfully deleted! users");
            const user  = {};
            user[payload.toString()] = firebase.firestore.FieldValue.delete();
            db.collection('usersIndex').doc('users').update( user );
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      }
      /*
        const uid = payload.uid
        if(typeof payload.role == "undefined")
        payload.role = 3;
        // context.commit("setUser", payload);
        delete payload.uid
        db.collection('users').doc(uid).set(payload)
        */
    }),
    updateUser: firestoreAction((context, payload) => {
        const uid = payload.uid
        if(typeof payload.role == "undefined")
        payload.role = 3;
        // context.commit("setUser", payload);
        delete payload.uid
        db.collection('users').doc(uid).set(payload)
    }),
    updateAccount: firestoreAction((context, payload) => {
        const uid = payload.uid
        if(typeof payload.role == "undefined")
        payload.role = 3;
        delete payload.uid
        db.collection('accounts').doc(uid).set(payload)
    }),
    addMeasurement: firestoreAction((context, payload) => {
        const uid = payload.user
        delete payload.user
        const day = payload.day;
        const data = {};
        delete payload.day;
        data[day] = payload;
        db.collection('measurements').doc(uid).update(data).then(function(){
          console.log("Data saved successfully.");
        }).catch(function(error) {
          console.log("Data could not be saved." + error);
          db.collection('measurements').doc(uid).set(data)
        });
        /*
        const datau = {stats: payload};
        console.log(uid,datau)
        db.collection('users').doc(uid).update(datau).then(function(){
          console.log("Data saved successfully.");
        });
        */
    }),
    removeMeasurement: firestoreAction((context, payload) => {
        const uid = payload.user
        delete payload.user
        const day = payload.day;
        const data = {};
        delete payload.day;
        data[day] = firebase.firestore.FieldValue.delete()
        db.collection('measurements').doc(uid).update(data).then(function(){
          console.log("Data deleted successfully.");
        }).catch(function(error) {
          console.log("Data could not be deleted." + error);
        });
    }),
    addTraining: firestoreAction((context, payload) => {
        const uid = payload.user
        delete payload.user
        const day = payload.day;
        const data = {};
        delete payload.day;
        data[day] = payload;
        db.collection('training').doc(uid).update(data).then(function(){
          console.log("Data saved successfully.");
        }).catch(function(error) {
          console.log("Data could not be saved." + error);
          db.collection('training').doc(uid).set(data)
        });
    }),
    removeTraining: firestoreAction((context, payload) => {
        const uid = payload.user
        delete payload.user
        const day = payload.day;
        const data = {};
        delete payload.day;
        data[day] = firebase.firestore.FieldValue.delete();
        db.collection('training').doc(uid).update(data).then(function(){
          console.log("Data deleted successfully.");
        }).catch(function(error) {
          console.log("Data could not be deleted." + error);
        });
    }),
    archiveUser: firestoreAction((context, payload) => {

        let collection = 'users';
        if(!isNaN(payload.user)) collection = 'accounts';
        console.log(collection);
        db.collection(collection).doc(payload.user).get().then((doc)=>{

          if (doc.exists) {
              const data = doc.data()

              db.collection('usersArchive').doc(payload.user).set(data);
              setTimeout(()=>{

                db.collection(collection).doc(payload.user).delete().then(() => {
                    console.log("Document successfully deleted! users");
                    const user  = {};
                    user[payload.user] = firebase.firestore.FieldValue.delete();
                    db.collection('usersIndex').doc('users').update( user );
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });

              },600);
          }
        });

    }),
    bindMeasurements: firestoreAction(({ bindFirestoreRef }, payload) => {
        return bindFirestoreRef('userMeasurements', db.collection('measurements').doc(payload.user))
    }),
    bindTrainings: firestoreAction(({ bindFirestoreRef }, payload) => {
        return bindFirestoreRef('userTrainings', db.collection('training').doc(payload.user))
    }),
    bindReccuring: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('recurring', db.collection('recurring'))
    }),
    addReccuring: firestoreAction((context, payload) => {
        return db.collection('recurring').add(payload)
    }),
    deleteRecurring: firestoreAction((context, payload) => {
        db.collection('recurring').doc(payload).delete()
    }),
    updateRecurring: firestoreAction((context, payload) => {
        const uid = payload.uid
        delete payload.uid
        db.collection('recurring').doc(uid).set(payload)
    }),
  },
  modules: {
  }
})
