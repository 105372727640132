/* eslint-disable no-console */

import localforage from 'localforage'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
      /* eslint-disable no-extra-boolean-cast */
      if (!!window.chrome) { // for chromium based browsers
        fetch('/av.json?t=' + Date.now())
          .then(response => {
            response.json().then(function (data) {
//              const r = confirm('Une nouvelle version de l\'application est disponible (' + data.version + ') available. Your version will be updated, alright? ' + data.message)
              // const r = confirm('Une nouvelle version de l\'application est disponible. Votre version sera mise à jour. ' + data.message)
              const r = true;
              if (r === true) {
                if (data.clearStorage === true) {
                  localforage.clear().then(function () {
                    // Run this code once the database has been entirely deleted.
                    console.log('Database is now empty. ')
                    //location.reload(true)
                  }).catch(function (err) {
                    // This code runs if there were any errors
                    console.log(err)
                  })
                }
              } else {
                console.log('You pressed Cancel!')
              }
            })
            console.log('response:', response)
          })
          .catch(error => {
            console.error(error)
          })
        }
    },
    updated () {
      console.log('New content is available; please refresh.')
      caches.keys().then(function(names) {
        for (const name of names) caches.delete(name);
        //window.location.reload(true)
      });
      if (!window.chrome) { // for non chromium browsers
      fetch('/av.json?t=' + Date.now())
        .then(response => {
          response.json().then(function (data) {
            //const r = confirm('There is a new version (' + data.version + ') available. Your version will be updated, alright? ' + data.message)
            // const r = confirm('Il y a une nouvelle version de l\'application est disponible. Votre version sera mise à jour. ' + data.message)
            const r = true;
            if (r === true) {
              if (data.clearStorage === true) {
                localforage.clear().then(function () {
                  // Run this code once the database has been entirely deleted.
                  console.log('Database is now empty. so there now.')
                  //location.reload(true)
                }).catch(function (err) {
                  // This code runs if there were any errors
                  console.log(err)
                })
              }
            } else {
              console.log('You pressed Cancel!')
            }
          })
          console.log('response:', response)
        })
        .catch(error => {
          console.error(error)
        })
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
