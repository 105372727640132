<template>
  <div class="week">
    <Day :day="week[1].date" @book="book" @count="incrementHours" first="true" :user="user" :coach="coach"/>
    <Day :day="week[2].date" @book="book" @count="incrementHours" :user="user" :coach="coach"/>
    <Day :day="week[3].date" @book="book" @count="incrementHours" :user="user" :coach="coach"/>
    <Day :day="week[4].date" @book="book" @count="incrementHours" :user="user" :coach="coach"/>
    <Day :day="week[5].date" @book="book" @count="incrementHours" :user="user" :coach="coach"/>
    <Day :day="week[6].date" @book="book" @count="incrementHours" :user="user" :coach="coach"/>
    <!-- Day :day="week[7].date" @book="book" :user="user" / -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Day from "../components/DayVue.vue";

export default {
 // eslint-disable-next-line
 name: "Week",
 components: {
   Day
 },
 props: ['day', 'user', 'height', 'coach'],
  data() {
    return {
      today: null,
      hoursCount: 0
    };
  },
  computed: {
    ...mapGetters(['getServerTime']),
    week() {
      const week = {};
      const today = new Date(this.today+'T12:00:00');
      for(let i = 1;i<8;i++) {
        week[i] = {date : new Date(new Date(today).setDate(today.getDate() - today.getDay()+i)).toISOString().slice(0, 10), id: i};
      }
      return week;
    }
  },
   // eslint-disable-next-line
  mounted () {
  },
  methods: {
  //  ...mapActions(["updateUserProfile"]),
     // eslint-disable-next-line
     // eslint-disable-next-line
     incrementHours(data) {
       this.hoursCount = this.hoursCount + parseInt(data);
        this.$emit('count',this.hoursCount);
     },
     book(data) {
       this.$emit('book', {hour: data.hour, day: data.day, add: false, booking: data.booking, confirmed: data.confirmed, coach: data.coach, title: data.title, note: data.note, color: data.color, floor: data.floor, group: data.group, user2: data.user2, userComment: data.userComment, managerComment: data.managerComment, tlength: data.tlength, userMark: data.userMark, user2Mark: data.user2Mark })
     }
  },
  created() {
    this.today = this.getServerTime.toISOString().slice(0, 10);
    if (typeof this.day != "undefined") this.today = this.day
  }

};
</script>
